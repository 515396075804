import { useMemo, useState } from 'react';
import { MaterialRecord, ScheduleAccess } from './types';
import MaterialTableItem from './components/MaterialTableItem';
import NoContent from 'components/projects/photos/NoContent';
import { LoaderX } from 'components/shared/Loader';
import PaginationComponent from './components/PaginationComponent';
import ArrowIcons from './components/ArrowIcons';

interface Props {
  data: MaterialRecord[];
  loading?: boolean;
  activeTab: string;
  onEdit: (material: MaterialRecord) => void;
  access: ScheduleAccess[]; 
}
const numberperPage = 20;
const MaterialScheduleTable = ({ data, loading, activeTab, onEdit, access }: Props) => {
  let numberofPages = Math.ceil(data?.length / numberperPage);
  const [current, setCurrent] = useState(0);

  

  const reversedData = useMemo(() => {
    return [...data].reverse();
  }, [data])

  const _data = useMemo(() => {
    return reversedData?.slice(current * numberperPage, (current + 1) * numberperPage);
  }, [current, reversedData]);

  return (
    <div className="rounded-md">
      {loading ? (
        <div className=" w-full flex rounded-md bg-white  items-center justify-center p-10">
          <LoaderX color="blue" />
        </div>
      ) : data?.length > 0 ? (
        <>
          <table className="  bg-white w-full ">
            <thead>
              <tr className="  border-b  font-semibold">
                <td className=" py-4 pl-4">S/N</td>
                <td className="py-4">Item</td>
                <td className="py-4">Quantity</td>
                <td className="py-4">Rate</td>
                <td className="py-4 flex">
                  Amount <ArrowIcons />
                </td>
                <td className="py-4">Notes</td>
                <td className="py-4 flex">
                  Category
                </td>
                <td className="py-4"></td>
              </tr>
            </thead>
            <tbody>
              {_data?.map((m, i) => (
                <MaterialTableItem
                  key={i}
                  s_n={current * numberperPage + i + 1}
                  {...m}
                  onEdit={onEdit}
                  activeTab={activeTab}
                  access={access}
                />
              ))}
            </tbody>
          </table>
          <PaginationComponent
            pageIndex={current}
            pageCount={numberofPages}
            setPageIndex={setCurrent}
          />
        </>
      ) : (
        <NoContent
          subtitle="Keep a record of materials needed to complete the project"
          title="No Material recorded Yet"
        />
      )}
    </div>
  );
};

export default MaterialScheduleTable;
