import { useContext } from 'react';
import { TbFolderFilled, TbBed } from 'react-icons/tb';
import buildingIcon from 'assets/buildingType.svg';
import SelectPowModal from './SelectPowModal';
import type { POW, ProfessionalBriefWithPows } from '../../types';
import { PMStoreContext } from '../../Context';

interface Props {
  project: ProfessionalBriefWithPows;
  pows: POW[];
}

const ProjectCard = ({ project, pows }: Props) => {
  const { pseudoProjectName, projectType, numberOfBedrooms, _id } = project;
  const { targetProject, handleProjectClick, showSelectModal, handleSelectClose } =
    useContext(PMStoreContext);
  const targetPows = pows.filter((pow) => pow.projectId === targetProject?._id);

  return (
    <>
      <div
        className="flex flex-col gap-2 bg-white p-4 rounded-md border border-ashShade-3 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          handleProjectClick(project);
        }}>
        <div className="grid place-items-center w-8 h-8 rounded-full bg-hoverblue">
          <TbFolderFilled size={16} color="#365EAF" />
        </div>
        <p className="font-semibold capitalize">{pseudoProjectName}</p>
        <div className="flex gap-1 items-center text-bash">
          <span className="flex items-center gap-1 text-bash">
            <TbBed color="#77828D" size={16} />
            <p className="text-sm">{numberOfBedrooms} Bedroom</p>
          </span>
          &bull;
          <span className="flex items-center gap-1 text-bash">
            <img src={buildingIcon} alt="duplex" className="w-4 h-4 text-bash" />
            <p className="text-bash text-sm capitalize">{projectType}</p>
          </span>
        </div>
        <div className="pt-2">
          <span className="bg-bbg py-1 px-2 text-bblue text-xs rounded-3xl">
            {pows.length} Work Program{pows.length > 1 && 's'}
          </span>
        </div>
      </div>
      {targetProject?._id === _id && showSelectModal && (
        <SelectPowModal
          closer={handleSelectClose}
          pows={targetPows}
          name={pseudoProjectName}
          project={targetProject}
          // handleSelectSubmit={handleSelectSubmit}
          // selectedProgram={selectedProgram}
          // handleSelect={handleSelect}
        />
      )}
    </>
  );
};
export default ProjectCard;
