import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

const useUserProcurementAccess = () => {
  const { data, loading } = useAppSelector((m) => m.procurement);
  const { procurementId } = useParams();

  let user = useAppSelector((m) => m.user);
  const current = useMemo(() => {
    return data.find((m) => m._id === procurementId);
  }, [procurementId, data]);

  const hasAccess = useMemo(() => {
    let access = current?.dashboard?.access?.find((m) => m.userId === user._id);
    return access;
  }, [current]);

  return { hasAccess };
};

export default useUserProcurementAccess;
