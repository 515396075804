import { StoreContext } from 'context';
import React, { useContext } from 'react';
import { TDisbursements } from 'store/slices/financeSlice';
import { formatWithComma } from 'Utils';

interface Props {
  disbursements: TDisbursements[];
}

const ContractorTranches = ({ disbursements }: Props) => {
  return (
    <div className="w-full  bg-white p-2 flex flex-col gap-y-4 overflow-y-auto rounded-md scrollbar-thin">
      {disbursements.map((m) => (
        <ContractorTranch {...m} />
      ))}
    </div>
  );
};

const ContractorTranch = ({ contractorAmount, createdAt }: TDisbursements) => {
  let { activeProject } = useContext(StoreContext);
  return (
    <div className="w-full lg:w-1/2  bg-ashShade-0 p-2 rounded-md">
      <p>
        {activeProject?.currency?.code || null} {formatWithComma(contractorAmount || 0)}
      </p>
      <p>{new Date(createdAt).toDateString().slice(4)}</p>
    </div>
  );
};

export default ContractorTranches;
