import { StoreContext } from 'context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Valuation } from './types';
import { formatter } from '../home/summary/Budget';
import { populate, TValuation } from 'store/slices/financeSlice';
import ValuationModal from './ValuationModal';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { IoEllipsisVertical } from 'react-icons/io5';
import { useClickOutSideComponent } from '../Team/Views/Components/OnScreen';
import DeleteModal from '../procurement/material-schedule/components/DeleteModal';
import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayError } from 'Utils';
import { useLocation } from 'react-router-dom';

const ValuationItem = ({ amount, dateOfValuation, notes, _id }: TValuation) => {
  let finance = useAppSelector((m) => m.finance);
  let { selectedProject } = useContext(StoreContext);
  const [hovered, setHovered] = useState(false);
  const [editing, setEditing] = useState(false);
  const [options, setOptions] = useState(false);
  const [willDelete, setWillDelete] = useState(false);
  let { pathname } = useLocation();
  let dispatch = useAppDispatch();
  let optionsRef = useRef(null);



  useClickOutSideComponent(optionsRef, () => {
    setOptions(false);
  });

  const deleteValuation = async () => {
    let { e, response } = await postForm('delete', 'financials/valuation/delete', {
      financialsId: finance?.data?._id,
      valuationId: _id
    });
    if (response) {
      dispatch(populate(response.data.data));
      setWillDelete(false);
    }
    if (e) displayError(e?.message || 'Could not delete valuation');
  };
  return (
    <>
      {editing && (
        <ValuationModal
          value={{ amount, _id, dateOfValuation, notes }}
          closer={() => setEditing(false)}
        />
      )}

      {willDelete && (
        <DeleteModal
          deleteWhat="Valuation"
          title="Delete Valuation"
          buttonText="Delete Valuation"
          handleDeleteSubmit={deleteValuation}
          closer={() => setWillDelete(false)}
        />
      )}
      <div className=" w-full relative flex flex-col sm:flex-row justify-start  sm:justify-between items-center p-4 bg-ashShade-0 rounded-md ">
        <div>
          <p>
            Valuation updated to{' '}
            <span>
              <strong>{` ${selectedProject?.currency?.code} ${formatter.format(
                amount as number
              )}`}</strong>
            </span>
          </p>
          <p className=" text-bash text-sm">{new Date(dateOfValuation).toDateString().slice(4)}</p>
        </div>
        <div className=" w-full sm:w-auto flex justify-between sm:justify-start items-center">
          <div
            onMouseLeave={() => setHovered(false)}
            onMouseOver={() => setHovered(true)}
            className=" underline cursor-pointer ">
            View Notes
          </div>
          {hovered && (
            <span className=" absolute z-10 min-w-[10%] right-0 p-2 top-12 rounded-md bg-black text-white text-sm ">
              Notes <br />
              {notes}
            </span>
          )}
          <span
            onClick={() => setOptions(true)}
            className=" cursor-pointer p-2 ml-2 hover:border-ashShade-2 border border-transparent rounded-full">
            <IoEllipsisVertical />
          </span>

          {options && (
            <div
              ref={optionsRef}
              className=" shadow-bnkle absolute right-4 min-w-[100px] top-4 bg-white rounded-md ">
              <TheOption
                icon={TbEdit}
                className=" text-sm whitespace-nowrap  "
                text="Edit"
                onClick={() => {
                  // setBudgetModal(true);
                  setEditing(true);
                }}
              />
              <TheOption
                icon={TbTrash}
                className=" text-sm whitespace-nowrap !text-bred hover:bg-bred hover:!text-white "
                text="Delete"
                iconColor="#A7194B"
                iconClass=" text-bred mr-2"
                iconHoveredColor="white"
                onClick={() => {
                  setWillDelete(true);
                  // navigateTo('./budget-history');
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ValuationItem;
