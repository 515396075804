import * as yup from 'yup';

export interface TOption {
  value: string;
  label?: string;
  userId: string;
  userType: string;
}
export interface Media {
  S3Key: string;
  ContentType: string;
}

interface initialValueProps {
  title: string;
  issuedBy: string;
  issuedDate: Date;
  instruction: string;
  media: Media[];
  recipients: TOption[];
  instructionNo: number;
}

export const initialValue: initialValueProps = {
  title: '',
  issuedBy: '',
  issuedDate: new Date(),
  instruction: '',
  media: [],
  recipients: [],
  instructionNo: 0
};

export const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  issuedBy: yup.string().required('Issued by is required'),
  issuedDate: yup.date().required('Issued date is required').nullable(),
  instruction: yup.string().required('Instruction is required'),
  recipients: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('value is required'),
        userId: yup.string().required('User ID is required'),
        userType: yup.string().required('User Type is required')
      })
    )
    .notRequired(),
  attachedFiles: yup.array().of(yup.string())
});
