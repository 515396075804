import { useState, useContext } from 'react';
import SelectField from 'components/shared/SelectField';
import Button from 'components/shared/Button';
import { currencies } from '../../../constants';
import { StoreContext } from 'context';
import { updateProjectBrief } from 'apis/projectBrief';
import { displaySuccess } from 'Utils';
import useFetch from 'Hooks/useFetch';

const index = () => {
  const { selectedProject, handleContext, data } = useContext(StoreContext);
  const selectedCurrency = selectedProject?.currency;
  const [formData, setFormData] = useState({
    currency: {
      code: selectedCurrency?.code || '',
      label: selectedCurrency?.label || ''
    }
  });
  const { load: updateLoader, isLoading: isUpdateCurrencyLoading } = useFetch();

  const handleCurrencyUpdate = async () => {
    if (!selectedProject) return;
    const payload = formData;
    updateLoader(updateProjectBrief(selectedProject._id, payload)).then((res) => {
      displaySuccess('Currency updated');
      const projectUpdate = { ...selectedProject, ...payload };
      const newProjects = data.map((one) =>
        one._id === selectedProject._id ? projectUpdate : one
      );
      handleContext('data', newProjects);
      handleContext('selectedProject', projectUpdate);
    });
  };

  return (
    <div className="w-full h-full text-bblack-1 mt-4">
      <div className="grid grid-cols-4 gap-5">
        <div className="bg-white px-6 py-5 text-sm rounded-xl">Currency</div>
        <div className="flex flex-col gap-12 col-span-3 bg-white pb-5 pt-2 px-6 rounded-xl">
          <SelectField
            label="Currency"
            showSearch
            value={formData.currency.code}
            data={currencies.map((one) => ({ ...one, label: `${one.label}  (${one.value})` }))}
            onChange={(val) => {
              const currency = currencies.find((one) => one.value === val);
              if (currency) {
                setFormData((prev) => ({
                  ...prev,
                  currency: { code: currency.value, label: currency.label }
                }));
              }
            }}
          />
          <span className="self-end">
            <Button
              type={formData.currency.code === selectedCurrency?.code ? 'muted' : 'primary'}
              text="Update"
              className="w-auto"
              isLoading={isUpdateCurrencyLoading}
              onClick={handleCurrencyUpdate}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
export default index;
