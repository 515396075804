import { getMessagingGroupAndMessages, MESSAGING_REST, postForm } from 'apis/postForm';
import { StoreContext } from 'context';
import { removeemptyFields } from 'pages/projectform/utils';
import React, { useContext, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addMessage, setSelection, updateGroupAndMessages } from 'store/slices/chatsSlice';
import axios from 'axios';
import { cleanError, displayError, displaySuccess } from 'Utils';
import { CustomEditor, SuperModal } from 'components/shared';
import { LoaderX } from 'components/shared/Loader';
import { BsSend } from 'react-icons/bs';
import useMembers from './useMembers';
import { SiteInstruction } from './types';
import { useNavigate } from 'react-router-dom';
import { socket } from 'pages/projects/Project/OneProject';

interface Prop {
  siteInstruction: SiteInstruction;
  closer: () => void;
}

const CommentModal = ({ siteInstruction, closer }: Prop) => {
  const [sending, setSending] = useState(false);
  let { data, selectedProjectIndex } = useContext(StoreContext);

  let chat = useAppSelector((m) => m.chats);
  let user = useAppSelector((m) => m.user);
  let [value, setValue] = useState('');
  let dispatch = useAppDispatch();

  let navigate = useNavigate();
  const handleSendMessage = async (message: string) => {
    if (message.length < 1) return;
    setSending(true);

    let { e, response } = await postForm('patch', 'site-instruction/add-response', {
      instructionId: siteInstruction._id,
      content: value
    });

    if (response) {
      let resp = await getMessagingGroupAndMessages(response.data.data.groupId);
      if (resp.response) {
        dispatch(updateGroupAndMessages(resp.response.data.data));
        socket.emit('join-group', resp.response.data.data._id);
        displaySuccess('Comment Added Successfully');
        dispatch(
          setSelection({
            id: response.data.data.groupId,
            type: 'Site Instructions'
          })
        );
        closer();
        navigate('../chats');
      }
    } else {
      displayError(cleanError(e, 'could not add comment'));
    }

    setSending((_) => false);
  };

  let { memberList } = useMembers();

  return (
    <SuperModal classes="  bg-black bg-opacity-30 " closer={closer}>
      <div className="w-full h-full flex flex-col items-center">
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className=" w-4/5 lg:w-1/2 xl:w-1/3 bg-white p-6 rounded-md mt-32 max-w-[516px] h-auto ">
          <div className="w-full justify-between  flex items-center">
            <span className=" text-xl text-bblack-1 font-semibold">
              Comment on {siteInstruction.title}
            </span>
            <span onClick={closer} className=" text-bash text-sm cursor-pointer">
              Close
            </span>
          </div>

          <div className="w-full flex justify-center mt-8">
            <div className="w-full" onSubmit={async (e) => {}}>
              <div onMouseDown={(e) => {}} className="relative w-full  my-3 rounded-3xl ">
                <CustomEditor
                  textValue={value}
                  textValueOnChange={(val: string) => {
                    setValue(val);
                  }}
                  placeholder="Type a message"
                  memberData={memberList}
                  height="75px"
                />
                <div
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="absolute z-10 bottom-2 right-0">
                  <button
                    type="submit"
                    disabled={sending}
                    onClick={async () => {
                      await handleSendMessage(value);
                    }}
                    className="p-2 border bg-ashShade-0 rounded-full hover:text-white text-bash ">
                    {sending ? (
                      <LoaderX color="blue" />
                    ) : (
                      <BsSend className="rotate-45" size={24} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SuperModal>
  );
};

export default CommentModal;
