import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa6';
import { hoverFade } from 'constants/globalStyles';
import { SiteInstruction } from '../siteInstructions/types';
import { isArrayNullOrEmpty } from 'Utils';
import parse from 'html-react-parser';
import '../../../../shared/CustomEditor/style.css';
import { GetDownloadSignedUrls } from 'apis/AwsFiles';
import { RecipientHoverCard } from '../siteInstructions/RecipientHoverCard';
import { ActionIconWithModal } from '../siteInstructions/ActionIconWithModal';
import { IDocument } from '@cyntler/react-doc-viewer';

import { FilePreviewModal } from '../siteInstructions/FilePreviewModal';
import { postForm } from 'apis/postForm';
import { useAppSelector } from 'store/hooks';
import useSiteInstruction from 'Hooks/useSiteInstruction';

interface Props {
  groupId: string;
}

export const InstructionPlacard: FC<Props> = ({ groupId }) => {
  let chat = useAppSelector((m) => m.chats);

  let siteInstruction = useAppSelector((m) => m.siteInstruction);

  const { getSiteInstructions } = useSiteInstruction(true);

  useEffect(() => {
    if (siteInstruction.data.length === 0) {
      getSiteInstructions();
    }
  }, []);

  let item = useMemo(() => {
    let group = chat.groups.siteInstructions[groupId];
    return siteInstruction.data.find((m) => m._id === group?.instructionId) as SiteInstruction;
  }, [chat, siteInstruction]);

  const [imageUrls, setImageUrls] = useState<{ uri: string; fileType: string }[]>([]);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [config, setConfig] = useState<IDocument[]>([]);
  const [fetching, setFetching] = useState(false);

  const recipientLabel = useMemo(() => {
    if (item?.recipients && isArrayNullOrEmpty(item?.recipients)) {
      return 'No Recipient';
    }
    return item?.recipients!.length === 1 ? `Recipient` : 'Recipients';
  }, [item]);

  const getImgUrl = useCallback(async (path: string) => {
    const res = await GetDownloadSignedUrls(path, 'bnkle-professional-docs');
    return res.data.url;
  }, []);

  const fetchImageUrls = useCallback(async () => {
    if (!item?.media) {
      return;
    }

    let _urls = await Promise.all(
      item?.media.map(
        (m) =>
          postForm('post', 'files/download', {
            Bucket: 'bnkle-professional-docs',
            S3Key: m.S3Key
          }) || []
      )
    );
    let viewerConfig: IDocument[] = [];

    let urls = _urls.map((m, i) => {
      if (m.response) {
        viewerConfig.push({
          uri: m.response.data.data.url,
          fileType: item.media ? item?.media[i]?.ContentType : '',
          fileName: item.media ? item?.media[i]?.S3Key : ''
        });
      }
    });

    setImageUrls(viewerConfig.map((m) => ({ uri: m.uri, fileType: m.fileType || '' })));
    setConfig(viewerConfig);
    setFetching(false);
  }, [item, getImgUrl]);

  useEffect(() => {
    fetchImageUrls();
  }, [fetchImageUrls]);

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section
        className={`flex flex-col gap-4 p-6  rounded-md bg-ashShade-0 border-ashShade-1  border font-satoshi ${
          item ? '' : ' hidden'
        }`}>
        <div className="flex flex-col gap-6 lg:flex-row justify-between">
          <div className="flex  items-center justify-between">
            <div className="flex items-center gap-3">
              {/* <FaAngleLeft color="#9099a8" onClick={onClose} className={`${hoverFade}`} /> */}
              <div>
                <p className="text-xl text-bblack-1 font-satoshi font-semibold">
                  {item?.instructionNo}. {item?.title}
                </p>
              </div>
            </div>
            <div className="lg:hidden">
              <ActionIconWithModal
                item={item}
                isModalOpen={isActionModalOpen}
                setIsModalOpen={(value: boolean) => setIsActionModalOpen(value)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-sm font-satoshi font-medium ">
          {/* <p className="text-bblack-1">Instructions</p> */}
          <div className="rsw-ce ul rsw-ce ol">
            <p className="">{parse(item?.instruction || '')}</p>
          </div>
        </div>
        {!isArrayNullOrEmpty(imageUrls) && (
          <div className="flex flex-col gap-2 text-sm font-satoshi font-medium">
            <p className="text-bblack-1">Attached files</p>
            <div className="grid grid-cols-2 md:grid-cols-3  gap-4 w-full">
              {imageUrls.map((url, index) => (
                <div
                  key={index}
                  className=" rounded-md w-full "
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveIndex(index);
                    setIsModalOpen(true);
                  }}>
                  <img
                    src={url.uri}
                    alt={`Attached file ${index + 1}`}
                    className="w-full h-full object-cover rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col flex-wrap justify-between gap-8 text-sm font-satoshi font-medium">
          <div>
            <p className="text-bblack-1"> Issued by</p>
            <p className="text-bash">{item?.issuedBy}</p>
          </div>
          {/* <div>
          <p className="text-bblack-1">{recipientLabel}</p>
          <RecipientHoverCard recipients={item?.recipients ?? []} />
        </div> */}
          <div>
            <p className="text-bblack-1">Date issued</p>
            <p className="text-bash">{new Date(item?.issuedDate).toDateString().slice(4)}</p>
          </div>
        </div>
      </section>

      {isModalOpen && (
        <FilePreviewModal
          activeIndex={activeIndex}
          onClose={() => setIsModalOpen(false)}
          fetching={fetching}
          config={
            item.media?.map((m) => ({
              S3Key: m.S3Key,
              Bucket: 'bnkle-professional-docs',
              ContentType: m.ContentType,
              alias: m.S3Key,
              project: 'pp',
              folder: 'f',
              timestamp: ''
            })) || []
          }
        />
      )}
    </>
  );
};
