import React, { createElement, useContext, useRef, useState } from 'react';
import { GroupCardProps } from './types';
import { toDecimalPlaceWithCommas } from 'components/shared/utils';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { openModal } from 'store/slices/financeSlice';
import { hoverFade } from 'constants/globalStyles';
import { StoreContext } from 'context';
import { FaEllipsisV } from 'react-icons/fa';
import { IoEllipsisVertical } from 'react-icons/io5';
import { useClickOutSideComponent } from '../Team/Views/Components/OnScreen';

const GroupCard = ({
  title,
  btn,
  btntitle,
  amount,
  bgColor,
  icon,
  iconColor,
  options,
  removeOptions
}: GroupCardProps) => {
  const { selectedProject } = useContext(StoreContext);
  const user = useAppSelector((m) => m.user);
  const dispatch = useAppDispatch();
  const [showOption, setShowOption] = useState(false);
  const optionsRef = useRef(null);
  useClickOutSideComponent(optionsRef, () => {
    setShowOption(false);
  });
  return (
    <div className="rounded-md w-full p-4 bg-white">
      <div className="w-full flex items-start justify-between">
        <div className=" flex items-center md:flex-col md:items-start">
          <div
            style={{ backgroundColor: bgColor }}
            className=" p-1 flex items-center justify-center rounded-full  ">
            {createElement(icon, { size: 24, color: iconColor })}
          </div>

          <div className="text-bblack-1 font-semibold ml-2 md:ml-0 md:mt-6">
            <p className=" text-xs text-bash ">{title}</p>
            <p className=" text-lg md:text-xl ">
              {selectedProject.currency?.code} {toDecimalPlaceWithCommas(amount)}
            </p>
          </div>
        </div>

        <div className=" relative ">
          {!removeOptions && (
            <span
              onClick={() => setShowOption(true)}
              className="p-2 flex items-center rounded-full hover:bg-ashShade-0 ">
              <IoEllipsisVertical color="#C8CDD4" />
            </span>
          )}

          {showOption && options ? (
            <span ref={optionsRef} className=" absolute right-0 top-0 ">
              {options}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
