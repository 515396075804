import React, { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = (e: Event) => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width, isMobile: width < 1024 };
};

export default useIsMobile;
