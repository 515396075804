import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { SiteInstruction } from 'components/projects/Team/Views/siteInstructions/types';

let initialState: {
  loading?: boolean;
  data: SiteInstruction[];
} = {
  loading: false,
  data: []
};

export const siteInstructionSlice = createSlice({
  name: 'siteInstruction',
  initialState,
  reducers: {
    loadSiteInstructions: (state, action) => {
      let newState = state;
      for (let x in action.payload) {
        newState[x as keyof typeof initialState] = action.payload[x];
      }
      return newState;
    },

    addSiteInstruction: (state, action: PayloadAction<SiteInstruction>) => {
      // state.data = [action.payload, ...state.data];
      let newState = state;
      newState.data.push(action.payload);
      return newState;
    },

    updateSiteInstruction: (state, action: PayloadAction<SiteInstruction>) => {
      const updatedInstruction = action.payload;

      state.data = state.data.filter((instruction) => instruction._id !== updatedInstruction._id);

      state.data = [updatedInstruction, ...state.data];
    },
    removeSiteInstruction: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((instruction) => instruction._id !== action.payload);
    }
  }
});

export const {
  loadSiteInstructions,
  addSiteInstruction,
  updateSiteInstruction,
  removeSiteInstruction
} = siteInstructionSlice.actions;

export const selectSiteInstruction = (state: RootState) => state.siteInstruction;
export default siteInstructionSlice.reducer;
