import React, { useEffect } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import BudgetHistoryTable from './budgetTable';
import { budgetHistory } from './constants';

const BudgetHistory = () => {
  let navigate = useNavigate();
  let finance = useAppSelector((m) => m.finance);
  let { pathname } = useLocation();

  useEffect(() => {
    if (!finance?.data?._id) {
      let link = pathname.split('/');
      navigate(link.slice(0, link.length - 2).join('/'));
    }
  }, []);
  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="flex sticky bg-projectBg top-0 z-20 items-center pb-2  text-sm cursor-pointer">
        <span className="text-borange mr-2 flex  items-center">
          <BsChevronLeft size={16} className="text-borange" />
        </span>
        <span className="text-bash ">{`${finance.data.bidName} Finance`}</span>
      </div>

      <p className=" font-semibold mt-10">Project Cost History</p>
      <BudgetHistoryTable modalToggler={() => {}} budgets={finance.data.projectBudget || []} />
    </div>
  );
};

export default BudgetHistory;
