import { useContext, useState, useCallback, useLayoutEffect, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TbChevronLeft, TbArrowNarrowLeft, TbSearch, TbSortDescending } from 'react-icons/tb';
import { StoreContext } from 'context';
import { hoverFade } from '../../../../../constants/globalStyles';
import ProjectCard from './ProjectCard';
import PowTemplateCard from './PowTemplateCard';
import { powTemplates } from '../utils/constants';
import PowDetailsModal from './PowDetailsModal';
import { PMStoreContext } from '../../Context';
import type { POW, ProfessionalBriefWithPows } from '../../types';
import Loader from 'components/shared/Loader';
import useFetch from 'Hooks/useFetch';
import { getPowsByProject } from 'apis/pow';

const initialWidth = window.innerWidth;
const rows = initialWidth > 1280 ? 4 : initialWidth > 1024 ? 3 : initialWidth > 768 ? 2 : 1;

const ImportPow = () => {
  const { selectedProjectIndex, data, menuProjects } = useContext(StoreContext);
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [rowItems, setRowItems] = useState(rows);
  const [allProjects, setAllProjects] = useState<ProfessionalBriefWithPows[]>([]);
  const [myProjects, setMyProjects] = useState<ProfessionalBriefWithPows[]>([]);
  const [search, setSearch] = useState('');
  const { load, isLoading: isFetchLoading } = useFetch<POW[]>({
    showDisplayError: false
  });
  const { selectedProgram, showDetailsModal, handleDetailsClose, handleSelect } =
    useContext(PMStoreContext);
  const projectId = data[selectedProjectIndex]._id;

  const goBack = () => {
    navigate(`/projects/${projectId}/management`);
  };

  useEffect(() => {
    const fetchProjectsWithPow = async () => {
      const projectsWithPows: ProfessionalBriefWithPows[] = [];
      const powPromises = menuProjects.map(async (project) => {
        try {
          const response = await load(getPowsByProject(project._id));
          const pows = response.data as POW[];
          if (pows.length > 0) {
            projectsWithPows.push({ ...project, pows });
          }
        } catch (error) {
          console.error('Error fetching Pows', error);
        }
      });
      await Promise.all(powPromises);
      setAllProjects(projectsWithPows);
      setMyProjects(projectsWithPows.slice(0, rowItems));
    };

    fetchProjectsWithPow();
  }, []);

  const resizeHandler = useCallback(() => {
    if (window.innerWidth > 1280) {
      if (rowItems !== 4) setRowItems(4);
    } else if (window.innerWidth > 1024) {
      if (rowItems !== 3) setRowItems(3);
    } else if (window.innerWidth > 768) {
      if (rowItems !== 2) {
        setRowItems(2);
      }
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const handleShowMore = () => {
    if (showMore) {
      setMyProjects(allProjects.slice(0, rowItems));
      setShowMore(false);
    } else {
      setMyProjects(allProjects);
      setShowMore(true);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div className="md:fixed top-12">
        <button className={'flex md:hidden items-center mt-2' + hoverFade} onClick={goBack}>
          <TbChevronLeft className="text-bash" />
          <p className="text-bash">Back</p>
        </button>
        <button
          className={'hidden md:flex items-center cursor-pointer hover:opacity-70'}
          onClick={goBack}>
          <TbArrowNarrowLeft className="text-black text-xl mr-2" />
          <p className="text-black text-sm">Back</p>
        </button>
      </div>
      <header className="pb-6 pt-4">
        <h4 className="font-Medium text-3xl text-black">Import POW</h4>
      </header>
      <div className="flex justify-between items-center pb-6">
        <h5 className="font-semibold">My projects</h5>
        <button onClick={handleShowMore} className="text-bblue font-sm font-medium">
          {showMore ? 'View less' : 'View more'}
        </button>
      </div>
      {isFetchLoading ? (
        <Loader height="full" />
      ) : (
        <div
          className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 pb-6 
        ">
          {myProjects.map((project) => {
            return <ProjectCard key={project._id} project={project} pows={project.pows} />;
          })}
        </div>
      )}
      {/* <div className="flex justify-between items-center pb-4">
        <h5 className="font-semibold">Program of work Templates</h5>
        <div className="flex gap-4 items-center">
          <div className="relative flex gap-4 items-center bg-white w-72 placeholder:text-bash placeholder:text-sm text-sm">
            <TbSearch color="#77828D" className="absolute top-3 left-3" size={16} />
            <input
              value={search}
              className="w-full border border-ashShade-4 pl-8 py-2 outline-none rounded-md"
              placeholder="Search Templates"
              onChange={handleSearch}
            />
          </div>
          <button className="flex items-center gap-3 bg-white text-bash text-sm py-2 px-4 rounded-md border border-ashShade-4">
            <TbSortDescending size={16} />
            <span>Filter</span>
          </button>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6">
        {powTemplates.map((template) => {
          return (
            <PowTemplateCard
              key={template._id}
              template={template}
              // selectedProgram={selectedProgram}
              // handleSelect={handleSelect}
            />
          );
        })}
      </div> */}

      {selectedProgram && showDetailsModal && <PowDetailsModal closer={handleDetailsClose} />}
    </>
  );
};

export default ImportPow;
