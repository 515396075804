import { toDecimalPlaceWithCommas } from 'components/shared/utils';
import React, { useContext, useMemo, useRef } from 'react';
import { IoClose, IoEllipsisVertical } from 'react-icons/io5';
import { TranchProps } from './types';
import { useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayError, displaySuccess, displayWarning, formatWithComma } from 'Utils';
import { updateField, openModal } from 'store/slices/financeSlice';
import Loader, { LoaderX } from 'components/shared/Loader';
import { StoreContext } from 'context';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { FaEllipsisVertical } from 'react-icons/fa6';
import DetailModal from './book-keeping/DetailModal';
import { useClickOutSideComponent } from '../Team/Views/Components/OnScreen';
import SuperModal from 'components/shared/SuperModal';
import { MdOutlineClose } from 'react-icons/md';
import TranchModal from './TranchModal';
import useRole from 'Hooks/useRole';

const Tranch = (m: TranchProps) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((m) => m.user);
  const { data } = useAppSelector((m) => m.finance);
  const { selectedProject, activeProject } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(false);
  const optionsRef = useRef(null);
  const [viewing, setViewing] = useState(false);
  const [editing, setEditing] = useState(false);
  const { isProfessional } = useRole();
  const handleDelete = async () => {
    setDeleting(true);

    let { e, response } = await postForm('patch', `financials/disbursement/remove`, {
      disbursementId: m._id
    });
    if (e) {
      displayWarning(e?.message || 'Could not delete payment');
    } else {
      let newList =
        (data.disbursements && data.disbursements.filter((j: any) => j._id !== m._id)) || [];
      dispatch(updateField({ field: 'disbursements', data: newList }));
    }

    setDeleting(false);
  };

  let code = useMemo(() => {
    return activeProject?.currency?.code;
  }, []);

  useClickOutSideComponent(optionsRef, () => {
    setOptions(false);
  });

  let isOldData = useMemo(() => {
    return m.amount && m?.contractorAmount === undefined;
  }, [m]);


  return (
    <>
      {viewing && (
        <SuperModal
          classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
          closer={() => {
            setViewing(false);
          }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className=" bg-white rounded-md p-6 mt-20 w-[calc(100%-20px)] md:w-4/5 lg:w-1/2 max-w-[500px] ">
            <div>
              <div className=" flex items-center justify-between ">
                <p className=" text-2xl font-semibold">Tranch Details</p>

                <span
                  onClick={() => {
                    setViewing(false);
                  }}
                  className=" p-2 rounded-full hover:bg-ashShade-0">
                  <MdOutlineClose size={24} color="#9099A8" />
                </span>
              </div>
              <div className="mt-6 p-4 bg-ashShade-0 rounded-md  ">
                <Item
                  label="Total payment"
                  underline
                  value={` ${code} ${formatWithComma(
                    m.amount || 0 + (m?.amount || 0) + (m?.amount || 0)
                  )}`}
                  valueClass="text-lg "
                />
                <Item
                  label="Contractor amount"
                  value={`${code} ${formatWithComma(
                    (isOldData ? m.amount : m.contractorAmount) || 0
                  )}`}
                  underline
                  valueClass="text-sm"
                />
                <Item
                  label="Admin amount"
                  value={`${code} ${formatWithComma(m.adminAmount || 0)}`}
                  underline
                  valueClass="text-sm"
                />
                <Item
                  label="Material amount"
                  value={`${code} ${formatWithComma(m.materialAmount || 0)}`}
                  underline
                  valueClass="text-sm"
                />
                {m?.disbursementItems?.map((k) => (
                  <Item
                    label={k.name}
                    value={`${code} ${formatWithComma(k.amount || 0)}`}
                    underline
                    valueClass="text-sm"
                  />
                ))}
                {/* <Item label="New entry" value="" /> */}
                <Item
                  label="Payment Date"
                  value={new Date(m?.paymentDate || new Date()).toDateString().slice(4)}
                  valueClass="text-sm"
                />
              </div>
            </div>
          </div>
        </SuperModal>
      )}

      {editing && (
        <TranchModal
          value={data.disbursements?.find((k) => k._id === m._id)}
          closer={() => setEditing(false)}
        />
      )}

      <tr className=" py-4 text-bash text-sm w-full ">
        <td className=" pl-4">{m.s_n}</td>
        <td className="py-2">
          {new Date(m?.paymentDate || m?.dueDate || new Date()).toDateString().slice(4)}
        </td>
        <td className="py-2 !text-black !font-semibold">
          {code} {formatWithComma(m.amount || 0)}
        </td>
        <td className="py-2">
          {code} {formatWithComma((isOldData ? m.amount : m.contractorAmount) || 0)}
        </td>
        <td className="py-2  ">
          {code} {formatWithComma(m?.adminAmount || 0)}
        </td>
        <td className="py-2">
          {code} {formatWithComma(m?.materialAmount || 0)}
        </td>

        <td className="py-2 relative  hover:cursor-pointer">
          <span
            onClick={() => {
              setViewing(true);
            }}
            className="  underline whitespace-nowrap font-medium text-bblue">
            {m.disbursementItems && m?.disbursementItems?.length > 0 ? `View details` : ''}
          </span>
        </td>
        <td className="py-2 relative ">
          <div>
            {!isProfessional && (
              <span
                onClick={() => {
                  setOptions(true);
                }}
                className=" p-2 w-8 h-8 flex rounded-full  hover:bg-ashShade-0 ">
                <FaEllipsisVertical />
              </span>
            )}

            {options && (
              <div
                ref={optionsRef}
                className=" top-6 z-10 right-2 absolute bg-white   rounded-md shadow-bnkle lg:w-[136px] ">
                <TheOption
                  icon={TbEdit}
                  text="Edit"
                  onClick={() => {
                    setEditing(true);
                  }}
                  className=" px-4"
                />
                <TheOption
                  loading={deleting}
                  className=" hover:text-redShades-2 text-redShades-2 hover:bg-redShades-1 px-4"
                  iconColor="#B63434"
                  iconHoveredColor="#B63434"
                  icon={TbTrash}
                  text="Delete"
                  onClick={handleDelete}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export const Item = ({
  label,
  value,
  underline,
  valueClass
}: {
  label?: string;
  value?: string;
  underline?: boolean;
  valueClass?: string;
}) => {
  return (
    <div
      className={` py-4  flex items-center justify-between ${
        underline ? ' border-b-2 border-ashShade-3  ' : ''
      } `}>
      <span className=" text-black text-sm font-semibold ">{label}</span>
      <span className={`text-bash ${valueClass}`}>{value}</span>
    </div>
  );
};

export default Tranch;
