import Button from 'components/shared/Button';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { TbAccessible, TbPlus } from 'react-icons/tb';
import { BsFunnel } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import InventoryMaterialModal from './components/InventoryMaterialModal';
import { useAppSelector } from 'store/hooks';
import InventoryTable from './components/InventoryTable';
import useInventory from 'Hooks/useInventory';
import ActivitySelector from './components/ActivitySelector';
import { Inventory, ActivityType, InventoryMaterial } from './types';
import { convertToProper } from 'components/shared/utils';
import InventoryFilter from './components/InventoryFilter';
import ProcurementTabs from '../layout/ProcurementTabs';
import { StoreContext } from 'context';
import InventoryTabs from './components/InventoryTabs';
import CreateInventoryModal from './components/CreateInventoryModal';
import InventoryAccessModal from './components/InventoryAccessModal';
import { useProcurementDetails } from '../layout/useProcurement';
import NoContent from 'components/projects/photos/NoContent';

const InventoryRegister = () => {
  let navigate = useNavigate();
  const { getRecords } = useInventory();
  let { selectedProject } = useContext(StoreContext);
  let { data, loading } = useAppSelector((m) => m.inventoryRegister);
  let user = useAppSelector((m) => m.user);
  const [activeInventory, setActiveInventory] = useState('');
  const [showAccessModal, setShowAccessModal] = useState(false);
  let { procurementItems } = useProcurementDetails();

  useEffect(() => {
    if (data.length < 1) {
      getRecords();
    }
  }, []);

  useEffect(() => {
    setActiveInventory(procurementItems?.inventoryId?._id || '');
  }, [procurementItems]);

  const current = useMemo(() => {
    return data.find((a) => a._id === activeInventory);
  }, [data, activeInventory]);

  let isEditor = false;
  current?.access.map((a) => {
    if (a.userId === user._id && a.accessType === 'editor') {
      isEditor = true;
    }
  });

  const hasAccess = useMemo(() => {
    let access = current?.access?.find((m) => m.userId === user._id);
    return access;
  }, [current]);

  const [showModal, setShowModal] = useState(false);
  const [editing, setEditing] = useState<InventoryMaterial | undefined>(undefined);
  const [showActivitySelector, setShowActivitySelector] = useState(false);
  const [activity, setActivity] = useState<ActivityType>('disburse');
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState<{ [key: string]: (Date | string)[] }>({
    material: [],
    receiver: [],
    date: []
  });
  const [editingInventory, setEditingInventory] = useState<Inventory | undefined>(undefined);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const selectInventory = (inventory: Inventory) => {
    setActiveInventory(inventory._id);
  };

  const handleEditing = (x: InventoryMaterial) => {
    setEditing(x);
    setShowModal(true);
  };

  const handleTabsEditing = (x: Inventory) => {
    setEditingInventory(x);
    setShowCreateModal(true);
  };

  const validData = data.filter(Boolean);

  let { materialData, receiverData } = useMemo(() => {
    let materialAcc: { [key: string]: boolean } = {};
    let receiverAcc: { [key: string]: boolean } = {};

    data.forEach((m) => {
      m?.materials.forEach((a) => {
        materialAcc[a.material] = true;
        receiverAcc[a.receivedBy] = true;
      });
    });

    const convertToOption = (list: string[]) => {
      return list.map((m) => ({ value: m, label: convertToProper(m) }));
    };

    return {
      materialData: convertToOption(Object.keys(materialAcc)),
      receiverData: convertToOption(Object.keys(receiverAcc))
    };
  }, [data]);

  const handleFilter = () => {
    let selectedInventory = data.find((m) => m._id === activeInventory);
    if (!selectedInventory) return [];
    let _inventoryMaterials = [...selectedInventory.materials];

    if (filters?.receiver && filters?.receiver.length > 0) {
      let receiver = filters?.receiver as string[];
      let _receiver = receiver?.map((m) => m.toLowerCase());
      _inventoryMaterials = _inventoryMaterials.filter((a) =>
        _receiver.includes(a.receivedBy.toLowerCase())
      );
    }

    if (filters?.material && filters.material.length > 0) {
      let materials = filters?.material as string[];
      let _materials = materials?.map((m) => m.toLowerCase());
      _inventoryMaterials = _inventoryMaterials.filter((a) =>
        _materials.includes(a.material.toLowerCase())
      );
    }

    if (filters?.date && filters?.date.length > 0) {
      let dates = filters?.date as Date[];
      let _dates = dates.map((m) => m.getTime());
      if (_dates[0] !== _dates[1]) {
        _inventoryMaterials = _inventoryMaterials.filter((a) => {
          let date = new Date(a.date).getTime();
          return date >= _dates[0] && date <= _dates[1];
        });
      }
    }
    return _inventoryMaterials.reverse();
  };

  const filtered = useMemo(handleFilter, [data, filters, activeInventory]);

  return (
    <div className=" bg-white px-6 rounded-b-lg ">
      {showAccessModal && (
        <InventoryAccessModal
          _id={activeInventory}
          closer={() => {
            setShowAccessModal(false);
          }}
        />
      )}
      <ProcurementTabs
        buttons={
          <>
            {hasAccess && (
              <div className="relative flex gap-2 pb-2">
                <Button
                  className="border-ashShade-2 border px-0 mx-0"
                  text=""
                  type="plain"
                  style={{
                    padding: '9px 18px'
                  }}
                  LeftIcon={<BsFunnel color="#9099A8" />}
                  onClick={() => setShowFilter(!showFilter)}
                />
                <Button
                  onClick={() => setShowAccessModal(true)}
                  className="border-ashShade-2 border px-0 mx-0"
                  text=""
                  type="plain"
                  style={{
                    padding: '9px 18px'
                  }}
                  LeftIcon={<TbAccessible color="#9099A8" />}
                />
                {isEditor && (
                  <Button
                    text=""
                    style={{
                      padding: '9px 18px'
                    }}
                    LeftIcon={<TbPlus color="white" />}
                    onClick={() => setShowActivitySelector(!showActivitySelector)}
                  />
                )}
                {showActivitySelector && (
                  <ActivitySelector
                    setActivity={setActivity}
                    setShowModal={setShowModal}
                    setShowActivitySelector={setShowActivitySelector}
                  />
                )}
              </div>
            )}
          </>
        }
      />

      {!hasAccess ? (
        <NoContent title="You do not have access" subtitle="  " />
      ) : (
        <div>
          {showCreateModal && (
            <CreateInventoryModal
              isEditing={Boolean(editingInventory)}
              value={editingInventory}
              closer={() => {
                setShowCreateModal(false);
                setEditingInventory(undefined);
              }}
            />
          )}

          {showModal && (
            <InventoryMaterialModal
              activity={activity}
              setShowActivitySelector={setShowActivitySelector}
              isEditing={Boolean(editing)}
              value={editing}
              closer={() => {
                setShowModal(false);
                setEditing(undefined);
              }}
              activeInventory={activeInventory}
            />
          )}

          {showFilter && (
            <div className="w-full my-4">
              <InventoryFilter
                onChange={(val: { [key: string]: (string | Date)[] }) => {
                  setFilters(val);
                }}
                materialData={materialData}
                receiverData={receiverData}
              />
            </div>
          )}


          <InventoryTable
            data={filtered}
            loading={loading}
            setEditing={handleEditing}
            activity={activity}
            activeInventory={activeInventory}
            setActivity={setActivity}
            isEditor={isEditor}
          />
        </div>
      )}
    </div>
  );
};

export default InventoryRegister;
