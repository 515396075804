import React, { FC, useState, useEffect, useRef, memo, useMemo } from 'react';
import { AiOutlineCheck, AiOutlineLoading } from 'react-icons/ai';
import IconInput from './IconInput';
import { truncateText } from './utils';
import { IoClose } from 'react-icons/io5';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';
import { errorStyle } from 'constants/globalStyles';
import { RxDividerHorizontal } from 'react-icons/rx';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';

export type TOption = {
  value: string;
  label?: string;
  userId?: string;
  userType?: string;
};

export interface SelectProps {
  onChange: (value: TOption[]) => void;
  showSearch?: boolean;
  placeholder?: string;
  className?: string;
  data?: TOption[];
  value?: TOption[];
  error?: string;
  label?: string;
  disabled?: boolean;
  showAcheck?: boolean;
  isLoading?: boolean;
  wrapperClassName?: string;
  labelClassName?: string;
  initialValue?: string[];
  placeholderClassName?: string;
  modalClassName?: string;
  showClearButton?: boolean;
  onClear?: () => void;
}

const MultiSelectField: FC<SelectProps> = ({
  error,
  value,
  label,
  placeholder,
  className,
  data = [],
  isLoading = false,
  showAcheck,
  labelClassName,
  showSearch = false,
  onChange,
  disabled = false,
  onClear
}) => {
  const selectRef = useRef<HTMLDivElement | null>(null);

  const [selectedValues, setSelectedValues] = useState<TOption[]>(value || []);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const filteredData = useMemo(() => {
    return query
      ? data.filter((option) => option.label?.toLowerCase().includes(query.toLowerCase()))
      : data;
  }, [query, data]);

  const handleToggle = () => {
    if (!disabled) setShowModal((prev) => !prev);
  };

  const handleSelect = (selectedOption: TOption) => {
    if (!selectedOption.value.trim()) return; // Ignore empty selections

    const updatedValues = selectedValues.some((option) => option.value === selectedOption.value)
      ? selectedValues.filter((option) => option.value !== selectedOption.value)
      : [...selectedValues, selectedOption];

    setSelectedValues(updatedValues);
    onChange(updatedValues);
  };

  const handleRemove = (value: string) => {
    const updatedValues = selectedValues.filter((option) => option.value !== value);
    setSelectedValues(updatedValues);
    onChange(updatedValues);
  };

  useEffect(() => {
    const cleanedValue = value?.filter((v) => v.value.trim() !== '') || [];
    setSelectedValues(cleanedValue);
  }, [value]);

  useClickOutSideComponent(selectRef, () => setShowModal(false));

  return (
    <div className={`relative  my-3  ${className}`} ref={selectRef}>
      <p className={`text-sm text-bash font-semibold ${labelClassName}`}>{label}</p>

      <div
        onClick={handleToggle}
        className={`border mt-1 rounded-md px-4 flex items-center justify-between py-2 cursor-pointer ${
          disabled && 'bg-gray-50'
        }`}>
        <div className="flex-1 flex items-center gap-x-2 overflow-x-auto">
          {selectedValues.length > 0 ? (
            selectedValues.map((option) => (
              <span
                key={option.value}
                className="whitespace-nowrap bg-ashShade-3 flex items-center rounded-md p-1 text-sm font-semibold">
                {truncateText(option.label || option.value, 20)}
                <button className="p-0.5" onClick={() => handleRemove(option.value)}>
                  <IoClose className="ml-1" />
                </button>
              </span>
            ))
          ) : (
            <span className={`text-bash font-semibold`}>{placeholder ?? 'Select'}</span>
          )}
        </div>
        {disabled ? (
          <RxDividerHorizontal className="text-bash" />
        ) : showModal ? (
          <TbChevronUp size={16} color="#9099A8" />
        ) : (
          <TbChevronDown size={16} color="#9099A8" />
        )}
      </div>

      {showModal && (
        <div className="absolute w-full bg-white border rounded-md shadow-lg mt-1 z-10">
          {showSearch && <IconInput value={query} placeholder="Search..." onChange={setQuery} />}
          <div className="max-h-40 h-fit overflow-y-scroll">
            {isLoading ? (
              <div className="flex mt-1 items-center px-5 py-1 rounded-md w-full bg-gray-100">
                <AiOutlineLoading className="animate-spin text-gray-700 mr-2 text-sm" />
                <p className="font-Medium text-black">Loading</p>
              </div>
            ) : filteredData?.length ? (
              filteredData.map((option, index) => (
                <div
                  key={option.value}
                  className={`flex items-center p-3 rounded-md whitespace-nowrap  hover:bg-blue-100 hover:text-bblue w-full ${
                    index ? null : 'mt-1'
                  }`}
                  onClick={() => handleSelect(option)}>
                  {showAcheck &&
                    selectedValues.some((selected) => selected.value === option.value) && (
                      <AiOutlineCheck className="text-white text-base mr-1" />
                    )}
                  <p
                    className={`w-full cursor-pointer truncate !text-left flex-[.95] ${
                      selectedValues.some((selected) => selected.value === option.value)
                        ? 'text-bash'
                        : ''
                    }`}>
                    {option.label || option.value}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center py-2">No options available</p>
            )}
          </div>
        </div>
      )}
      {error && <p className={errorStyle}>{error}</p>}
    </div>
  );
};

export default memo(MultiSelectField);
