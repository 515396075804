import { FC, useMemo } from 'react';
import { Recipient } from './types';
import { useAppSelector } from 'store/hooks';
import { TOption } from './validation';
import { isArrayNullOrEmpty } from 'Utils';
import { truncateText } from 'components/shared/utils';

interface Props {
  recipients: Recipient[];
}

export const RecipientHoverModal: FC<Props> = ({ recipients }) => {
  const team = useAppSelector((m) => m.team);

  const memberList = useMemo(() => {
    const allMembers = Object.values(team.data);
    return allMembers.map((member) => ({
      value: member.name,
      userId: member._id
    }));
  }, [team.data]);
  return (
    <>
      {!isArrayNullOrEmpty(recipients) && (
        <ul
          className={`absolute flex flex-col gap-2 top-full left-0 mt-1 p-3 w-[145px] bg-bblack-2 shadow-lg rounded-md border border-ashShade-3 z-50`}
          style={{ zIndex: 9999 }}>
          {recipients.map((recipient) => {
            const member = memberList.find((member) => member.userId === recipient.userId);
            return (
              member && (
                <li
                  key={member.userId}
                  className="text-ashShade-5 text-sm font-medium font-satoshi">
                  {truncateText(member.value, 18)}
                </li>
              )
            );
          })}
        </ul>
      )}
    </>
  );
};
