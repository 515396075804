import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SiteInstructionItemCard } from './SiteInstructionItemCard';
import NoContent from 'components/projects/photos/NoContent';
import { SiteInstructionDetailCard } from './SiteInstructionDetailCard';
import { SiteInstruction } from './types';
import useSiteInstruction from 'Hooks/useSiteInstruction';
import { useAppSelector } from 'store/hooks';
import { isArrayNullOrEmpty } from 'Utils';
import { LoaderX } from 'components/shared/Loader';

export const SiteInstructions: FC = () => {
  const [selectedInstruction, setSelectedInstruction] = useState<SiteInstruction | null>(null);

  const { getSiteInstructions } = useSiteInstruction(true);
  let { data, loading } = useAppSelector((m) => m.siteInstruction);

  useEffect(() => {
    if (isArrayNullOrEmpty(data) && !loading) {
      getSiteInstructions();
    }
  }, []);

  const handleOpenDetailModal = useCallback((instruction: SiteInstruction) => {
    setSelectedInstruction(instruction);
  }, []);

  const handleCloseDetailModal = useCallback(() => {
    setSelectedInstruction(null);
  }, []);

  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => b.instructionNo - a.instructionNo);
  }, [data]);

  useEffect(() => {
    if (selectedInstruction) {
      let updatedInstruction = data.find((m) => m._id === selectedInstruction._id);
      setSelectedInstruction(updatedInstruction ?? selectedInstruction);
    }
  }, [data]);

  return (
    <section>
      <p className="text-2xl text-bblack-1 font-semibold font-satoshi mb-4">Site Instructions</p>
      {loading ? (
        <div className=" w-full flex rounded-md bg-white  items-center justify-center p-10">
          <LoaderX color="blue" />
        </div>
      ) : selectedInstruction ? (
        <SiteInstructionDetailCard item={selectedInstruction} onClose={handleCloseDetailModal} />
      ) : (
        <div className="flex flex-col gap-4">
          {!isArrayNullOrEmpty(sortedData) ? (
            sortedData.map((item, index) => (
              <SiteInstructionItemCard
                key={item._id}
                item={item}
                index={index}
                onOpenDetailModal={handleOpenDetailModal}
              />
            ))
          ) : (
            <NoContent title="No site instruction created" subtitle="." />
          )}
        </div>
      )}
    </section>
  );
};
