import { FC, useEffect, useState } from 'react';
import { SuperModal } from 'components/shared';
import { CgClose } from 'react-icons/cg';
import { LoaderX } from 'components/shared/Loader';
import DocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import { Doc } from 'components/projects/documents/document-repo/SingleDocModal';
import { postForm } from 'apis/postForm';

interface Props {
  onClose: () => void;
  fetching?: boolean;
  config: Doc[];
  activeIndex?: number;
}

export const FilePreviewModal: FC<Props> = ({ onClose, config, activeIndex }) => {
  const [fetching, setFetching] = useState(false);
  const [viewerConfig, setViewerConfig] = useState<IDocument[]>([]);
  const fetchfiles = async () => {
    setFetching(true);

    let viewerConfig: IDocument[] = [];
    let some: any[] = [];
    let newDoc = await Promise.all(
      config.map((m) =>
        postForm('post', 'files/download', {
          Bucket: m.Bucket,
          S3Key: m.S3Key
        })
      )
    );

    newDoc.map((m, i) => {
      if (m.response) {
        viewerConfig.push({
          uri: m.response.data.data.url,
          fileType: config[i].ContentType,
          fileName: config[i]?.alias
        });
      }
    });

    setViewerConfig(viewerConfig);
    setFetching(false);
  };

  useEffect(() => {
    fetchfiles();
  }, []);

  return (
    <SuperModal closer={onClose}>
      <div className="  h-screen flex flex-col justify-center w-screen  p-5 lg:px-20 lg:pt-15 bg-ashShade-5 overflow-y-auto ">
        <div className="w-full relative flex justify-end">
          <span
            onClick={onClose}
            className=" top-10 mb-5 right-10  w-10 h-10 z-[1000] flex items-center justify-center bg-white rounded-full text-transparent">
            <CgClose color="#C8CDD4" size={20} />
          </span>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="w-full h-full relative  overflow-y-auto">
          {fetching ? (
            <div className="w-full h-full flex flex-col items-center justify-center ">
              <LoaderX color="blue" />
              <p className=" nt-2 text-2xl text-bblue">Loading..</p>
            </div>
          ) : (
            <DocViewer
              initialActiveDocument={viewerConfig[activeIndex ?? 0]}
              className="rounded-xl"
              documents={viewerConfig}
              pluginRenderers={DocViewerRenderers}
            />
          )}
        </div>
      </div>
    </SuperModal>
  );
};
