import { displayError } from 'Utils';
import { postForm } from 'apis/postForm';
import { StoreContext } from 'context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { loadSiteInstructions } from 'store/slices/siteInstructionSlice';

const useSiteInstruction = (wait: boolean = false) => {
  let { activeProject } = useContext(StoreContext);
  let dispatch = useAppDispatch();

  let projectId = useMemo(() => {
    return activeProject?._id;
  }, [activeProject]);
  const getSiteInstructions = useCallback(async () => {
    if (projectId) {
      dispatch(loadSiteInstructions({ loading: true, data: [] }));
      let { e, response } = await postForm('get', `site-instruction/list?project=${projectId}`);

      if (response) {
        dispatch(loadSiteInstructions({ data: response.data.data, loading: false }));
      } else {
        dispatch(loadSiteInstructions({ loading: false }));
        displayError(e?.message || 'Could not fetch site instructions');
      }
    }
  }, [projectId]);

  useEffect(() => {
    if (!wait) {
      getSiteInstructions();
    }
  }, [projectId]);

  return { getSiteInstructions };
};

export default useSiteInstruction;
