import { FC, useCallback } from 'react';
import Button from 'components/shared/Button';
import SuperModal from 'components/shared/SuperModal';
import { CgClose } from 'react-icons/cg';
import { PiWarningCircleFill } from 'react-icons/pi';

interface Props {
  closer: () => void;
  handleCancelSubmit: () => Promise<void>;
  title: string;
  cancelWhat: string;
  buttonText: string;
  isLoading: boolean;
}

export const CancelModal: FC<Props> = ({
  closer,
  handleCancelSubmit,
  title,
  cancelWhat,
  buttonText,
  isLoading
}) => {
  const handleSubmit = useCallback(async () => {
    await handleCancelSubmit();
  }, [handleCancelSubmit]);

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={() => {}}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md py-6 mt-20 mb-10 w-3/4 max-w-[500px] flex flex-col gap-4 ">
        <div className="flex items-center justify-between px-6">
          <div className="flex items-center gap-2">
            <span>
              <PiWarningCircleFill className="text-redShade-0" fontSize={20} />
            </span>
            <p className=" text-sm sm:text-xl font-Medium text-bblack-1">{title}</p>
          </div>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            <CgClose fontSize={24} color="#9099A8" />
          </span>
        </div>

        <div className="border  border-pbg  text-bash sm:text-lg font-normal px-6 py-6">
          <p>Are you sure you want to cancel this {cancelWhat}</p>
          <p>You cannot undo this.</p>
        </div>

        <div className=" w-full flex flex-col sm:flex-row sm:justify-end gap-4 px-2 sm:px-6">
          <Button onClick={closer} type="secondary" text="Go back" />
          <Button  isLoading={isLoading} onClick={handleSubmit} type="danger" text={buttonText} />
        </div>
      </div>
    </SuperModal>
  );
};
