import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Material, MaterialFile } from './types';
import SuperModal from 'components/shared/SuperModal';
import InputField from 'components/shared/InputField';
import Button from 'components/shared/Button';
import { DatePicker } from 'components/shared/DatePicker';
import SelectDate from 'components/shared/SelectDate';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addRecord, updateRecord } from 'store/slices/bookKeepingSlice';
import * as yup from 'yup';
import { StoreContext } from 'context';
import { postForm } from 'apis/postForm';
import { convertToNumber, displayError, displaySuccess, formatWithComma } from 'Utils';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { debounce } from 'lodash';
import { MaterialSelectFields } from 'components/shared';
import { AiOutlineClose } from 'react-icons/ai';
import { setLoading } from 'store/slices/teamSlice';
import { IoClose } from 'react-icons/io5';
import { _getFileUrl, getFileUrl } from 'components/shared/utils';
import { uploadAllWithOptions } from 'Hooks/useProjectImages';
import { file } from 'jszip';
import { removeemptyFields } from 'pages/projectform/utils';

interface Props {
  closer: () => void;
  isEditing?: boolean;
  value?: Material;
}

const initialValue = {
  date: new Date(),
  material: '',
  quantity: '0',
  unit: '',
  rate: '0',
  amount: '0',
  description: '',
  vendor: '',
  category: '',
  orderNo: '',
  files: []
};

const convertRecord = (val: Material) => {
  let newVal: { [key: string]: any } = { ...val };
  newVal.amount = val.amount.toString();
  newVal.quantity = val.quantity.toString();
  newVal.rate = val.rate.toString();
  if (newVal.files && newVal.files.length > 0)
    newVal.files = newVal.files.map((m: MaterialFile) =>
      removeemptyFields({ ...m, fileId: undefined })
    );
  return newVal as Material;
};

const AddRecordModal = ({ closer, isEditing, value }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  let { activeBook } = useAppSelector((m) => m.bookKeeping);
  const { selectedData } = useContext(StoreContext);
  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm, isValid } =
    useFormik({
      initialValues: value ? convertRecord(value) : initialValue,
      onSubmit: (data) => {
        let _data: Material & { _id?: string } = { ...data } as Material;
        if (!_data?._id) {
          _data._id = Math.random().toString();
        }
        _data.amount = convertToNumber(data.amount as unknown as string);
        _data.quantity = convertToNumber(data.quantity as unknown as string);
        _data.rate = convertToNumber(data.rate as unknown as string);
        if (isEditing) {
          _edit(_data);
        } else _submit(_data);
      },
      validationSchema: yup.object({
        date: yup.string().required(),
        material: yup.string().required(),
        quantity: yup.string().required().not(['0'], 'Field cannot be zero'),
        unit: yup.string().required(),
        rate: yup.string().required().not(['0'], 'Field cannot be zero'),
        amount: yup.string().required().not(['0'], 'Field cannot be zero'),
        description: yup.string().required('please enter description'),
        vendor: yup.string().required(),
        category: yup.string().required(),
        orderNo: yup.string().required().not(['0'], 'Field cannot be zero')
      }),
      validateOnBlur: true
    });

  const [files, setFiles] = useState<File[]>([]);
  const [removedFiles, setRemovedFiles] = useState<MaterialFile[]>([]);

  useEffect(() => {
    let quantity = convertToNumber(values.quantity as string);
    let rate = convertToNumber(values.rate as string);
    let finalAmount = quantity * rate;
    setFieldValue('amount', formatWithComma(finalAmount));
  }, [values.quantity, values.rate]);

  const _submit = async (data: Omit<Material, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }
    let _files: MaterialFile[] = [];
    if (files.length > 0) {
      let serverFiles = await uploadAllWithOptions(files, selectedData._id, {});
      _files = serverFiles
        .filter((m) => m.response)
        .map((m) => ({
          S3Key: m.response.data.data.key,
          ContentType: m.file.type,
          Bucket: 'bnkle-professional-docs'
        })) as MaterialFile[];
    }
    data.files = _files;

    const { e, response } = await postForm('patch', `financials/bookkeeping/add-material`, {
      bookId: activeBook,
      materials: [data]
    });
    if (response) {
      displaySuccess('Record added successfully');
      dispatch(updateRecord(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _edit = async (data: Omit<Material, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _data: any = { ...data };

    for (let x of ['_id', 'project', '__v', 'createdBy', 'createdAt']) {
      delete _data[x];
    }
    _data['materialId'] = data._id;

    if (files.length > 0) {
      let serverFiles = await uploadAllWithOptions(files, selectedData._id, {});

      _data['files'] = [
        ..._data['files'],
        ...(serverFiles
          .filter((m) => m.response)
          .map((m) => ({
            S3Key: m.response.data.data.key,
            ContentType: m.file.type,
            Bucket: 'bnkle-professional-docs'
          })) as MaterialFile[])
      ];
    }

    const { e, response } = await postForm('patch', `financials/bookkeeping/update-material`, {
      ..._data,
      bookId: activeBook
    });
    if (response) {
      displaySuccess('Record updated successfully');
      dispatch(updateRecord(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _handleNumberChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === '') {
      setFieldValue(field, '0');
      return;
    }
    let notString = /[^0-9.,]/i.test(val);
    if (notString) {
      return;
    }
    if (val[val.length - 1] === '.' && val[val.length - 2] !== '.') {
      setFieldValue(field, val);
      return;
    }
    if (val.endsWith('0') && /\.[\d]{1,}/i.test(val)) {
      setFieldValue(field, val);
      return;
    }

    let num = convertToNumber(val);
    if (!isNaN(num)) {
      let formatedValue = new Intl.NumberFormat('en-US').format(num);
      setFieldValue(field, formatedValue);
    }
  };

  let inputRef = useRef<HTMLInputElement>(null);

  const date = useMemo(() => {
    return new Date(values.date);
  }, [values.date]);

  const removeFileIndex = (i: number) => () => {
    let newList = [...files].filter((m, k) => {
      return i !== k;
    });
    setFiles(newList);
  };

  const removeServerFileIndex = (i: number) => () => {
    if (values.files) {
      let newItem = values.files[i];
      setRemovedFiles([...removedFiles, newItem]);
      setFieldValue(
        'files',
        values.files.filter((m) => m.S3Key !== newItem.S3Key)
      );
    }
  };

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 w-1/2 max-w-[500px] ">
        <div className="flex items-center justify-between">
          <p className=" text-xl font-semibold">
            {isEditing ? 'Edit Record' : 'Record Expenditure'}
          </p>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            Close
          </span>
        </div>
        <SelectDate
          className=" mt-8"
          error={((touched.date && errors?.date) || '') as string}
          placeholder="select date"
          wrapperClassName=" !border-ashShade-4 "
          minDate={new Date(0)}
          maxDate={new Date()}
          initialValue={value?.date ? new Date(value.date) : new Date()}
          value={date}
          label="Purchased Date"
          onChange={(e) => {
            console.log(e);
            if (e) {
              setFieldValue('date', e.toISOString());
            }
          }}
        />

        <InputField
          error={(touched.orderNo && errors.orderNo) || ''}
          name="orderNo"
          value={values.orderNo}
          onChange={handleChange}
          type="text"
          label="Order  Number"
          placeholder="e.g 1000"
          className=" !text-bblack-1 "
        />

        <MaterialSelectFields
          categoryValue={values.category as string}
          materialValue={values.material}
          categoryError={(touched.category && errors.category) || ''}
          materialError={(touched.material && errors.material) || ''}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />

        <div className="flex gap-x-4 items-center">
          <div>
            <p className=" text-bash">Quantity</p>
            <div className="flex rounded-md border px-2 py-1 items-center gap-x-2 border-ashShade-4 mt-1">
              <span
                onClick={(e) => {
                  let val = convertToNumber(values.quantity as string);
                  if (val > 0) {
                    setFieldValue('quantity', formatWithComma(val - 1));
                  }
                }}
                className="  p-2 hover:bg-ashShade-0 rounded-full ">
                <TbMinus />
              </span>

              <input
                className=" outline-none w-1/2 text-bblack-1 "
                name="quantity"
                value={values.quantity}
                onChange={_handleNumberChange('quantity')}
                type="text"
                placeholder="quantity"
              />
              <span
                onClick={(e) => {
                  let val = convertToNumber(values.quantity as string);
                  setFieldValue('quantity', formatWithComma(val + 1));
                }}
                className=" p-2 hover:bg-ashShade-0 rounded-full ">
                <TbPlus />
              </span>
            </div>
          </div>

          <InputField
            error={(touched.unit && errors.unit) || ''}
            name="unit"
            value={values.unit}
            onChange={handleChange}
            label="Unit"
            placeholder="e.g Bags"
            className=" !flex-1 !text-bblack-1"
          />
        </div>

        <div className=" flex items-center gap-x-4">
          <InputField
            error={(touched.rate && errors.rate) || ''}
            name="rate"
            value={values.rate}
            onChange={_handleNumberChange('rate')}
            type="text"
            label="Rate"
            placeholder="e.g 1000"
            className=" !text-bblack-1 "
          />
          <InputField
            error={(touched?.amount && errors.amount) || ''}
            name="amount"
            value={values.amount}
            onChange={_handleNumberChange('amount')}
            type="text"
            label="Amount"
            placeholder="e.g 100,000"
            className=" !text-bblack-1 "
          />
        </div>

        <div className=" flex items-center gap-x-4">
          <InputField
            error={(touched.vendor && errors.vendor) || ''}
            name="vendor"
            value={values.vendor}
            onChange={handleChange}
            type="text"
            label="Vendor"
            placeholder="e.g CostCo"
            className=" !text-bblack-1 "
          />
        </div>

        <div className=" mt-6">
          <p className=" text-bash text-sm font-semibold">Upload Image</p>
          <input
            ref={inputRef}
            onChange={(e) => {
              let _files = Object.values(e.target.files || {});
              setFiles([...files, ..._files]);
            }}
            type="file"
            multiple
            className="hidden"
            accept="image/*"
          />
          <div className=" flex flex-wrap gap-3 mt-3 ">
            <span
              onClick={() => {
                inputRef.current?.click();
              }}
              className="  flex items-center justify-center border-dashed border rounded border-ashShade-2  w-12 h-12  ">
              <span className=" bg-ashShade-2 flex items-center justify-center rounded-full">
                <TbPlus color="white" size={12} className="m-0.5" />
              </span>
            </span>

            {values?.files?.map((m, i) => {
              return <AttachedImage source={m} onDelete={removeServerFileIndex(i)} />;
            })}

            {files.map((m, i) => (
              <AttachedImage source={URL.createObjectURL(m)} onDelete={removeFileIndex(i)} />
            ))}
          </div>
          {!(files.length > 0 || (values.files && values?.files?.length > 0)) && (
            <p className="text-sm text-bred">Please attach one or more files</p>
          )}
        </div>

        <InputField
          isTextArea
          error={(touched.description && errors.description) || ''}
          name="description"
          value={values.description}
          onChange={handleChange}
          label="Description"
          placeholder="e.g comments"
          className=" !text-bblack-1 "
        />

        <div className=" flex justify-end gap-x-4">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            type={isValid ? 'primary' : 'muted'}
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text={isEditing ? 'Save Changes' : 'Record Expenditure'}
          />
        </div>
      </div>
    </SuperModal>
  );
};

interface AttachedImageProps {
  source: string | MaterialFile;
  onDelete: () => void | Promise<void>;
}
const AttachedImage = ({ source, onDelete }: AttachedImageProps) => {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleClose = async () => {
    setLoading(true);
    onDelete();
    setLoading(true);
  }; //

  useEffect(() => {
    const getFile = async () => {
      let url = '';
      if (typeof source === 'string') {
        url = source;
      } else {
        let res = await _getFileUrl(source.S3Key);
        if (res) url = res;
      }

      return setImageUrl(url);
    };
    getFile();
  }, []);

  return (
    <div className=" relative w-12 h-12 border-ashShade-0 border rounded   ">
      <img src={imageUrl} className=" w-full h-full object-fill rounded  " />
      <span
        onClick={handleClose}
        className=" absolute z-10 -right-1.5 bg-redShade-0 -top-1.5 p-0.5 rounded-full">
        <IoClose color="white" size={10} />
      </span>
    </div>
  );
};

export default AddRecordModal;
