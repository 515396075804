import React, { useContext, useEffect, useMemo, useState } from 'react';
import { InventoryMaterial, ActivityType } from '../types';
import SuperModal from 'components/shared/SuperModal';
import InputField from 'components/shared/InputField';
import Button from 'components/shared/Button';
import SelectDate from 'components/shared/SelectDate';
import { useFormik, FormikTouched } from 'formik';
import { useAppDispatch } from 'store/hooks';
import { addInventoryMaterial, updateInventoryMaterial } from 'store/slices/inventorySlice';
import * as yup from 'yup';
import { convertToNumber, formatWithComma, displaySuccess, displayError } from 'Utils';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { postForm } from 'apis/postForm';
import useInventory from 'Hooks/useInventory';
import { MaterialSelectFields } from 'components/shared';
// import { FormikTouched } from 'formik';

interface Props {
  closer: () => void;
  isEditing?: boolean;
  value?: InventoryMaterial;
  activity: ActivityType;
  setShowActivitySelector: React.Dispatch<React.SetStateAction<boolean>>;
  activeInventory: string;
}

const InventoryMaterialModal = ({
  closer,
  isEditing,
  value,
  activity,
  setShowActivitySelector,
  activeInventory
}: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { getRecords } = useInventory();

  const initialValue = {
    date: new Date(),
    category: '',
    material: '',
    quantity: '0',
    unit: '',
    workArea: '',
    disbursedBy: activity === 'disburse' ? '' : undefined,
    returnedBy: activity === 'return' ? '' : undefined,
    location: '',
    notes: '',
    receivedBy: ''
  };

  const convertInventory = (val: InventoryMaterial) => {
    let newVal: { [key: string]: any } = { ...val };
    newVal.quantity = val.quantity.toString();

    return newVal as InventoryMaterial;
  };

  let { disburseOrReturnField, disburseOrReturnLabel } = useMemo(() => {
    const disburseOrReturnField = activity === 'disburse' ? 'disbursedBy' : 'returnedBy';
    const disburseOrReturnLabel = activity === 'disburse' ? 'Disbursed By' : 'Returned By';
    return { disburseOrReturnField, disburseOrReturnLabel };
  }, [activity]);

  const validationSchema = useMemo(
    () =>
      yup.object({
        date: yup.string().required(),
        category: yup.string(),
        material: yup.string().required(),
        quantity: yup.string().required().not(['0'], 'Field cannot be zero'),
        unit: yup.string().required(),
        workArea: yup.string().required(),
        [disburseOrReturnField]: yup.string().required(),
        location: yup.string().required(),
        notes: yup.string(),
        receivedBy: yup.string().required()
      }),
    [disburseOrReturnField, activity]
  );

  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm } = useFormik(
    {
      initialValues: value ? convertInventory(value) : initialValue,
      onSubmit: (data) => {
        let _data: InventoryMaterial & { _id?: string } = { ...data } as InventoryMaterial;
        if (!_data?._id) {
          _data._id = Math.random().toString();
        }
        _data.quantity = convertToNumber(data.quantity as unknown as string);
        _data.activityType = activity;

        const _cleanedData = {
          ..._data,
          [activity === 'disburse' ? 'returnedBy' : 'disbursedBy']: undefined
        };

        if (isEditing) {
          _edit(_cleanedData);
        } else {
          _submit(_cleanedData);
        }
      },
      validationSchema: validationSchema,
      validateOnBlur: true
    }
  );
  useEffect(() => {}, [errors, values]);

  const _submit = async (data: Omit<InventoryMaterial, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }
    let _data = { ...data } as any;
    if (!_data['notes']) {
      delete _data['notes'];
    }

    for (let x of ['returnedBy', 'disbursedBy']) {
      activity === 'disburse' ? delete _data['returnedBy'] : delete _data['disbursedBy'];
    }

    const { e, response } = await postForm('patch', `procurements/inventory/add-material`, {
      inventoryId: activeInventory,
      materials: [_data]
    });

    if (response) {
      displaySuccess('Record added successfully');
      dispatch(addInventoryMaterial({ inventoryId: activeInventory, material: _data }));
      getRecords();
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const _edit = async (data: Omit<InventoryMaterial, '_id'> & { _id?: string }) => {
    setLoading(true);
    const { _id, ...restData } = data;

    let _data = { ...restData } as any;

    for (let x of ['activity', 'activeInventory', 'isEditor']) {
      delete _data[x];
    }

    for (let x of ['returnedBy', 'disbursedBy']) {
      activity === 'disburse' ? delete _data['returnedBy'] : delete _data['disbursedBy'];
    }

    if (!_data['notes']) {
      delete _data['notes'];
    }

    const { e, response } = await postForm('patch', `procurements/inventory/update-material`, {
      inventoryId: activeInventory,
      materialId: _id,
      ..._data
    });
    if (response) {
      displaySuccess('Record updated successfully');
      dispatch(
        updateInventoryMaterial({ inventoryId: activeInventory, material: { ..._data, _id } })
      );
      getRecords();
      resetForm();
      closer();
    } else displayError(e?.message || '');
    setLoading(false);
  };

  const _handleNumberChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === '') {
      setFieldValue(field, '0');
      return;
    }
    let notString = /[^0-9.,]/i.test(val);
    if (notString) {
      return;
    }
    if (val[val.length - 1] === '.' && val[val.length - 2] !== '.') {
      setFieldValue(field, val);
      return;
    }
    if (val.endsWith('0') && /\.[\d]{1,}/i.test(val)) {
      setFieldValue(field, val);
      return;
    }

    let num = convertToNumber(val);
    if (!isNaN(num)) {
      let formatedValue = new Intl.NumberFormat('en-US').format(num);
      setFieldValue(field, formatedValue);
    }
  };

  const date = useMemo(() => {
    return new Date(values.date);
  }, [values.date]);

  const _touched = touched as FormikTouched<InventoryMaterial>;
  const _values = values as typeof values & Record<typeof disburseOrReturnField, string>;
  const _errors = errors as typeof errors & Record<typeof disburseOrReturnField, string>;

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md p-6 mt-20 w-1/2 max-w-[500px] ">
        <div className="flex items-center justify-between">
          <p className=" text-xl font-medium">
            {isEditing
              ? 'Edit Inventory'
              : activity === 'disburse'
                ? 'Disburse from Inventory'
                : 'Return to Inventory'}
          </p>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            Close
          </span>
        </div>
        <MaterialSelectFields
          categoryValue={_values.category}
          materialValue={_values.material}
          materialError={(_touched?.material && _errors?.material) || ''}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <InputField
          error={(_touched?.receivedBy && _errors?.receivedBy) || ''}
          name="receivedBy"
          value={_values.receivedBy}
          onChange={handleChange}
          label="Received By"
          placeholder="Receiver's name"
          className=" !text-bblack-1 "
        />
        <div className="flex gap-x-4 items-center">
          <div>
            <p className=" text-bash">Quantity</p>
            <div className="flex rounded-md border px-2 py-1 items-center gap-x-2 border-ashShade-4 mt-1">
              <span
                onClick={(e) => {
                  let val = convertToNumber(_values.quantity as string);
                  if (val > 0) {
                    setFieldValue('quantity', formatWithComma(val - 1));
                  }
                }}
                className="  p-2 hover:bg-ashShade-0 rounded-full ">
                <TbMinus />
              </span>

              <input
                className=" outline-none w-1/2 text-bblack-1 "
                name="quantity"
                value={_values.quantity}
                onChange={_handleNumberChange('quantity')}
                type="text"
                placeholder="quantity"
              />
              <span
                onClick={(e) => {
                  let val = convertToNumber(_values.quantity as string);
                  setFieldValue('quantity', formatWithComma(val + 1));
                }}
                className=" p-2 hover:bg-ashShade-0 rounded-full ">
                <TbPlus />
              </span>
            </div>
          </div>

          <InputField
            error={(_touched.unit && _errors.unit) || ''}
            name="unit"
            value={_values.unit}
            onChange={handleChange}
            label="Unit"
            placeholder="e.g Sacks"
            className=" !flex-1 !text-bblack-1"
          />
        </div>

        <div className=" flex items-center gap-x-4">
          <InputField
            error={(_touched.workArea && _errors.workArea) || ''}
            name="workArea"
            value={_values.workArea}
            onChange={handleChange}
            type="text"
            label="Work area"
            placeholder="e.g Foundation"
            className=" !text-bblack-1 "
          />
          <InputField
            error={
              (_touched[disburseOrReturnField as keyof typeof _touched] &&
                _errors[disburseOrReturnField]) ||
              ''
            }
            name={disburseOrReturnField}
            value={_values[disburseOrReturnField]}
            onChange={handleChange}
            type="text"
            label={disburseOrReturnLabel}
            placeholder="e.g John"
            className=" !text-bblack-1 "
          />
        </div>

        <InputField
          error={(_touched.location && _errors.location) || ''}
          name="location"
          value={_values.location}
          onChange={handleChange}
          type="text"
          label="Location"
          placeholder="e.g Setraco"
          className=" !text-bblack-1 "
        />

        <SelectDate
          className=" mt-8 text-bash"
          error={((_touched.date && _errors?.date) || '') as string}
          placeholder="Select date"
          wrapperClassName=" !border-ashShade-4"
          minDate={new Date(0)}
          maxDate={new Date()}
          initialValue={value?.date ? new Date(value.date) : new Date()}
          value={date}
          label="Date Received"
          onChange={(e) => {
            if (e) {
              setFieldValue('date', e.toISOString());
            }
          }}
        />

        <InputField
          isTextArea
          name="notes"
          value={_values.notes}
          onChange={handleChange}
          label="Notes (Optional)"
          placeholder="Enter a description..."
          className=" !text-bash "
        />

        <div className=" flex justify-end gap-x-4">
          <Button
            onClick={() => {
              closer();
              setShowActivitySelector(false);
            }}
            text="Cancel"
            type="secondary"
            className="border border-ashShade-4 text-bblack-1"
          />
          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
              setShowActivitySelector(false);
            }}
            className={`${
              isEditing ? 'bg-bblue' : activity === 'disburse' ? 'bg-redShades-2' : 'bg-bgreen-0'
            }`}
            text={isEditing ? 'Save Changes' : activity === 'disburse' ? 'Disburse' : 'Return'}
          />
        </div>
      </div>
    </SuperModal>
  );
};

export default InventoryMaterialModal;
