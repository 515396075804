import { postForm } from 'apis/postForm';
import { StoreContext } from 'context';
import React, { useContext, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addProcurement, loadProcurements, updateProcurement } from 'store/slices/procurementSlice';
import { removeRecord as removeSupply } from 'store/slices/supplySlice';
import { removeRecord as removeMaterialSchedule } from 'store/slices/materialScheduleSlice';
import { deleteInventory } from 'store/slices/inventorySlice';
import { displayError } from 'Utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ProcurementType } from './types';

let actionsmap = {
  'material-schedule': removeMaterialSchedule,
  supply: removeSupply,
  inventory: deleteInventory
};

const useProcurement = (wait = false) => {
  const { data, activeProject } = useContext(StoreContext);
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  let { projectId } = useParams();
  let { data: _procurements } = useAppSelector((m) => m.procurement);
  let { procurementId } = useParams();

  const getProcurements = async () => {
    dispatch(loadProcurements({ loading: true, data: [] }));
    let { e, response } = await postForm(
      'get',
      `procurements/dashboard/list?projectId=${activeProject?._id}`
    );

    dispatch(loadProcurements({ loading: false, data: response ? response?.data?.data : [] }));
  };

  const refetchCurrent = async () => {
    let current = _procurements.find((m) => m._id === procurementId);
    if (current) {
      let { e, response } = await postForm(
        'get',
        `procurements/dashboard/find?dashboardId=${current._id}`
      );
      if (response) {
        dispatch(updateProcurement(response.data.data));
      }
    }
  };

  const createProcurement = async () => {
    let name = 'procurement ' + new Date().getTime().toString().slice(7);

    let services = {
      'material-schedule': 'scheduleId',
      supply: 'supplyId',
      inventory: 'inventoryId'
    };

    const createServiceItem = async (service: string) => {
      return await postForm('post', `procurements/${service}/add`, {
        name,
        project: activeProject._id,
        description: `auto-${name}-${service}`
      });
    };

    const deleteServiceItem = async (id: string, service: string) => {
      let res = await postForm(
        'delete',
        `procurements/${service}/delete?${services[service as keyof typeof services]}=${id}`
      );
      if (res.response) {
        dispatch(actionsmap[service as keyof typeof actionsmap]);
      }
    };
    let keys = Object.keys(services);
    let allCalls = await Promise.all(keys.map((m) => createServiceItem(m)));
    let unsuccessful = allCalls.find((m) => m.e !== undefined);
    if (unsuccessful) {
      await Promise.all(
        allCalls.map(async (m, i) => {
          if (m.response) deleteServiceItem(m.response?.data.data?._id, keys[i]);
        })
      );

      displayError('Could not create procurement');

      return;
    }

    let { e, response } = await postForm('post', 'procurements/initialize-dashboard', {
      projectId: activeProject?._id,
      scheduleId: allCalls[0]?.response.data.data?._id,
      supplyId: allCalls[1]?.response.data.data?._id,
      inventoryId: allCalls[2]?.response.data.data?._id,
      name
    });

    if (response) {
      dispatch(addProcurement(response.data.data));
      navigate(`/projects/${projectId}/procurement/${response?.data?.data?._id}`);
    } else {
      displayError(e?.message);
    }
  };

  useEffect(() => {
    if (!wait) {
      getProcurements();
    }
  }, []);

  return { getProcurements, createProcurement, refetchCurrent };
};

const useProcurementDetails = () => {
  let { procurementId } = useParams();
  let { data, loading } = useAppSelector((m) => m.procurement);
  let procurement = useMemo(() => {
    return data.find((m) => m._id === procurementId);
  }, [data, procurementId]);
  return (procurement ?? {}) as ProcurementType;
};

export { useProcurementDetails };

export default useProcurement;
