import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export type TExpenditure = {
  title: string;
  taskId: string;
  amount: number;
  status: string;
  description: string;
  _id: string;
  createdAt: Date;
};

export type PaymentType = 'contractor' | 'admin' | 'others';

export type TPayment = {
  file: {
    bucket: string;
    key: string;
  };
  amount: number;
  createdAt: string;
  isConfirmed: boolean;
  date: string;
  _id: string;
  type?: PaymentType;
  createdBy?: string;
  confirmedBy: string;
  confirmedOn: string;
};

export interface TDisbursements {
  amount: number;
  paymentDate: string;
  disbursementItems: DisbursementItem[];
  adminAmount: number;
  contractorAmount: number;
  materialAmount: number;
  isConfirmed: boolean;
  dueDate: string;
  _id: string;
  createdAt: string;
}

export interface DisbursementItem {
  _id: string;
  name: string;
  amount: number;
}
export interface TValuation {
  createdBy: string;
  notes: string;
  amount: number;
  dateOfValuation: string;
  _id: string;
  timestamp: string;
}

export interface TProjectBudget {
  contractorBudget: number;
  materialBudget: number;
  adminFees: number;
  otherFees: number;
  tag: string;
  paymentDate: string;
  _id: string;
}
export type TFinance = {
  bidId?: string;
  bidName?: string;
  payments?: TPayment[];
  estimatedBudget?: number;
  expenditure?: TExpenditure[];
  disbursements?: TDisbursements[];
  projectId?: string;
  __v?: 0;
  _id?: string;
  contractor?: string;
  valuation?: TValuation[];
  projectBudget?: TProjectBudget[];
};

interface FinanceState {
  data: TFinance;
  modal: {
    isEditing: boolean;
    name?: 'budget' | 'payment' | 'tranch' | 'expenditure';
    open: boolean;
    _id?: string;
  };
}

let initialState: FinanceState = {
  data: {},
  modal: {
    isEditing: false,
    name: undefined,
    open: false
  }
};
export const FinanceSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    populate: (state, object: PayloadAction<TFinance>) => {
      return { data: { ...state.data, ...object.payload }, modal: state.modal };
    },
    updateField: (state, object) => {
      let newState: any = state;
      newState.data[object.payload.field] = object.payload.data;
      return newState;
    },
    addFinanceDisbursement: (state, object: PayloadAction<TDisbursements>) => {
      let newState: any = state;

      if (newState.data['disbursements']) {
        newState.data['disbursements'].push(object.payload);
      } else {
        newState.data['disbursements'] = [object.payload];
      }

      return newState;
    },

    updateFinanceDisbursement: (state, object: PayloadAction<TDisbursements>) => {
      let newState: any = state;

      newState.data['disbursements'] = newState.data['disbursements'].map((m: TDisbursements) => {
        if (m._id === object.payload._id) {
          return object.payload;
        } else return m;
      });

      return newState;
    },
    closeModal: (state) => {
      let newState: any = state;
      newState.modal = {
        isEditing: false,
        name: undefined,
        open: false
      };

      return newState;
    },
    openModal: (state, object) => {
      let newState: any = state;
      newState.modal = { open: true, ...object.payload };
      return newState;
    }
  }
});

export const {
  populate,
  updateField,
  closeModal,
  openModal,
  addFinanceDisbursement,
  updateFinanceDisbursement
} = FinanceSlice.actions;

export const selectFinance = (state: RootState) => state.finance;
export default FinanceSlice.reducer;
