import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Media, SiteInstruction } from './types';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addSiteInstruction, updateSiteInstruction } from 'store/slices/siteInstructionSlice';

import { StoreContext } from 'context';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess, isArrayNullOrEmpty } from 'Utils';
import { initialValue, TOption, validationSchema } from './validation';
import { IoCloseOutline } from 'react-icons/io5';
import { hoverFade } from 'constants/globalStyles';
import {
  MultiSelectField,
  SuperModal,
  InputField,
  Button,
  SelectDate,
  CustomEditor
} from 'components/shared';
import SelectField from 'components/shared/SelectField';
import { uploadAllWithOptions } from 'Hooks/useProjectImages';
import useFiles from 'components/projects/documents/document-repo/useFiles';
import { FileFolder } from 'components/projects/documents/types';
import { GetDownloadSignedUrls } from 'apis/AwsFiles';

interface Props {
  closer: () => void;
  isEditing?: boolean;
  value?: SiteInstruction;
}
const siteInstructionFolderName = 'site-instructions';

export const SiteInstructionFormModal: FC<Props> = ({ closer, isEditing, value }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const team = useAppSelector((m) => m.team);
  const { fileFolders } = useAppSelector((m) => m.folder);
  const siteInstructions = useAppSelector((m) => m.siteInstruction.data);
  const { selectedData } = useContext(StoreContext);
  let { createFolder } = useFiles(true);

  const [selectedFiles, setSelectedFiles] = useState<File[]>();
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);

  const memberList = useMemo(() => {
    const allMembers = Object.values(team.data);
    return allMembers.map((member) => ({
      value: member.name,
      userId: member._id,
      userType: member.type
    })) as TOption[];
  }, [team]);

  const simplifiedMemberList = useMemo(() => {
    return memberList.map((member) => ({
      id: member.userId,
      name: member.value
    }));
  }, [memberList]);

  const nextInstructionNumber = useMemo(() => {
    const lastInstruction = siteInstructions.length;
    return lastInstruction + 1;
  }, [siteInstructions]);

  let {
    errors,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    isValid,
    dirty
  } = useFormik({
    initialValues: {
      ...initialValue,
      instructionNo: nextInstructionNumber,
      ...(isEditing && {
        ...value,
        issuedBy: value?.issuedBy || '',
        media: (value?.media || []).map((mediaItem) => ({
          S3Key: mediaItem.S3Key,
          ContentType: mediaItem.ContentType
        })),
        instructionNo: isEditing ? value?.instructionNo : nextInstructionNumber,
        recipients:
          value?.recipients?.map((recipient) => {
            const matchedMember = memberList.find((member) => member.userId === recipient.userId);
            return {
              value: matchedMember ? matchedMember.value : '',
              userId: recipient.userId,
              userType: recipient.userType
            };
          }) || []
      })
    },
    onSubmit: (data) => {
      let _data: SiteInstruction & { _id?: string } = { ...data } as unknown as SiteInstruction;
      if (!_data?._id) {
        _data._id = Math.random().toString();
      }
      if (isEditing) {
        handleEditForm(_data);
      } else handleSubmitForm(_data);
    },
    validationSchema,
    validateOnBlur: true
  });

  const getMediaFromFiles = useCallback(
    async (files: File[]) => {
      if (isArrayNullOrEmpty(files)) {
        return;
      }

      let siteInstructionFolder = fileFolders.find(
        (file) => file.name === siteInstructionFolderName
      );

      if (!siteInstructionFolder) {
        let { response, e } = await createFolder(siteInstructionFolderName);
        if (response) {
          siteInstructionFolder = response.data.data as FileFolder;
        } else {
          displayError('Could not create Site Instruction Folder');
        }
      }

      const uploads = await uploadAllWithOptions(files, selectedData?._id, {
        folderId: siteInstructionFolder?._id
      });

      if (uploads) {
        return uploads.map((upload, idx) => ({
          S3Key: upload.response.data?.data?.key,
          ContentType: files[idx].type,
          Bucket: 'bnkle-professional-docs'
        }));
      }
    },
    [selectedData]
  );

  const handleSubmitForm = async (data: Omit<SiteInstruction, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _id = data._id;
    if (!isEditing) {
      delete data?._id;
    }

    const media = selectedFiles && (await getMediaFromFiles(selectedFiles));

    const payload = {
      title: data.title,
      project: selectedData?._id,
      issuedBy: data.issuedBy,
      issuedDate: data.issuedDate,
      instruction: data.instruction,
      media,
      recipients: data.recipients?.map(({ userId, userType }) => ({ userId, userType }))
    };

    const { e, response } = await postForm('post', `site-instruction/add`, payload);

    if (response) {
      displaySuccess('Site instruction added successfully');
      dispatch(addSiteInstruction(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const handleEditForm = async (data: Omit<SiteInstruction, '_id'> & { _id?: string }) => {
    setLoading(true);
    let _data: any = { ...data };

    for (let x of ['_id', 'project', '__v', 'createdBy', 'createdAt']) {
      delete _data[x];
    }

    let filteredFiles = selectedFiles;
    const existingMediaKeys = value?.media?.map((mediaItem) => mediaItem.S3Key) || [];
    filteredFiles = selectedFiles?.filter((file) => !existingMediaKeys.includes(file.name));

    const media = filteredFiles && (await getMediaFromFiles(filteredFiles));

    if (media && media.length > 0) {
      const mediaPayload = { instructionId: data._id, media };
      const { e: mediaError, response } = await postForm(
        'patch',
        `site-instruction/add-media`,
        mediaPayload
      );

      if (!response && mediaError) {
        displayError(mediaError?.message || '');
        setLoading(false);
        return;
      }
    }

    for (const s3Key of removedFiles) {
      const mediaToRemove = value?.media?.find((mediaItem) => mediaItem.S3Key === s3Key);

      if (mediaToRemove) {
        const removeMediaPayload = {
          instructionId: data._id,
          mediaId: mediaToRemove._id
        };

        const { e: removeMediaError, response: removeMediaResponse } = await postForm(
          'patch',
          `site-instruction/delete-media`,
          removeMediaPayload
        );

        if (!removeMediaResponse && removeMediaError) {
          displayError(removeMediaError?.message || '');
          setLoading(false);
          return;
        }
      }
    }

    const payload = {
      instructionId: data._id,
      title: data.title,
      instruction: data.instruction
    };

    const { e, response } = await postForm('patch', `site-instruction/update`, payload);

    if (response) {
      displaySuccess('Site instruction updated successfully');
      dispatch(updateSiteInstruction(response.data.data));
      resetForm();
      closer();
    } else displayError(e?.message || '');

    setLoading(false);
  };

  const date = useMemo(() => {
    return new Date(values.issuedDate);
  }, [values.issuedDate]);

  const fetchImageUrls = async (media: Media[]) => {
    try {
      const urls = await Promise.all(media.map((file) => getImgUrl(file.S3Key)));
      const files = await Promise.all(
        urls.map((url, index) => createFileFromUrl(url, media[index]))
      );
      setSelectedFiles(files);
    } catch (error) {
      console.error('Error fetching media URLs: ', error);
    }
  };

  const createFileFromUrl = async (url: string, media: Media) => {
    const blob = await fetchFileAsBlob(url);
    return new File([blob], media.S3Key, { type: media.ContentType });
  };

  const getImgUrl = async (path: string) => {
    const res = await GetDownloadSignedUrls(path, 'bnkle-professional-docs');
    return res.data.url;
  };

  const fetchFileAsBlob = async (url: string) => {
    const response = await fetch(url);
    return response.blob();
  };

  useEffect(() => {
    if (isEditing && value?.media && value?.media?.length > 0) {
      fetchImageUrls(value.media);
    }
  }, [isEditing, value]);

  return (
    <SuperModal
      classes="bg-black bg-opacity-60 flex flex-col px-2 sm:px-4 items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white flex flex-col rounded-md p-6 mt-16 lg:mt-20  w-full md:w-[618px] ">
        <div className="flex items-center justify-between">
          <p className=" text-xl font-medium">
            {isEditing ? 'Edit Site Instruction' : 'Create Site Instruction'}
          </p>
          <IoCloseOutline size={24} className={`${hoverFade} text-bash`} onClick={closer} />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="flex flex-col">
          <div className="flex flex-col lg:flex-row items-center gap-x-4 mt-6">
            <InputField
              name="instructionNo"
              value={values.instructionNo}
              onChange={() => {}}
              type="number"
              label="Instruction number"
              placeholder="Instruction number"
              className=" !text-bblack-1 "
              disabled
            />
            <SelectDate
              className="mb-1"
              error={((touched.issuedDate && errors?.issuedDate) || '') as string}
              placeholder="select date"
              wrapperClassName=" !border-ashShade-4 "
              minDate={new Date(0)}
              initialValue={value?.issuedDate ? new Date(value.issuedDate) : new Date()}
              value={date}
              label="Date Issued"
              onChange={(e) => {
                if (e) {
                  setFieldValue('date', e.toISOString());
                }
              }}
              disabled={isEditing}
            />
          </div>
          <InputField
            error={(touched?.title && errors?.title) || ''}
            name="title"
            value={values.title}
            onChange={handleChange}
            label="Title"
            placeholder="title"
            className=" !text-bblack-1 "
          />
          <SelectField
            value={values.issuedBy}
            placeholder="Select issuer"
            label="Issuer"
            error={(touched?.issuedBy && errors?.issuedBy) || ''}
            data={memberList}
            className="!flex-1 !text-bblack-1"
            onChange={(selectedValues) => {
              setFieldValue('issuedBy', selectedValues);
            }}
            disabled={isEditing}
          />
          <MultiSelectField
            value={values.recipients}
            placeholder="Select recipient"
            label="Recipient"
            data={memberList}
            className="!flex-1 !text-bblack-1"
            onChange={(selectedValues) => {
              setFieldValue('recipients', selectedValues);
            }}
            disabled={isEditing}
          />
          <CustomEditor
            label="Description"
            textValue={values.instruction}
            textValueOnChange={(instruction) => {
              setFieldValue('instruction', instruction);
            }}
            fileValue={selectedFiles}
            fileValueOnChange={(files) => {
              setSelectedFiles(files);
              setFieldValue(
                'media',
                files.map((file: File) => ({
                  S3Key: file.name,
                  ContentType: file.type
                }))
              );
            }}
            error={(touched?.instruction && errors?.instruction) || ''}
            placeholder="Enter a description"
            memberData={simplifiedMemberList}
            setRemovedFiles={setRemovedFiles}
            isEditing={isEditing}
          />
        </div>
        <div className=" flex flex-col-reverse  sm:flex-row justify-end mt-6 gap-4">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            isLoading={loading}
            onClick={() => {
              handleSubmit();
            }}
            text={isEditing ? 'Save Changes' : 'Create Instruction'}
            type={isValid && dirty ? 'primary' : 'muted'}
          />
        </div>
      </div>
    </SuperModal>
  );
};
