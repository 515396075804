import { FC, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa6';
import { hoverFade } from 'constants/globalStyles';
import { SiteInstruction } from './types';
import { RecipientHoverCard } from './RecipientHoverCard';
import { ActionIconWithModal } from './ActionIconWithModal';
import useIsMobile from './useIsMobile';
import { useAppSelector } from 'store/hooks';
import { toMonthDayCommaYear } from 'Utils';

interface Props {
  item: SiteInstruction;
  index: number;
  onOpenDetailModal: (instruction: SiteInstruction, index: number) => void;
}

export const SiteInstructionItemCard: FC<Props> = ({ item, index, onOpenDetailModal }) => {
  let { isMobile, width } = useIsMobile();
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  let team = useAppSelector((m) => m.team);
  const handleDetailClick = () => {
    if (width < 1440 && !item.isCancelled) {
      onOpenDetailModal(item, index);
    }
  };
  return (
    <section
      className={`flex flex-col  xl:flex-row xl:items-center xl:justify-between gap-8 p-6 rounded-md bg-white font-satoshi font-semibold`}
      onClick={handleDetailClick}>
      <div className="flex  justify-between">
        <div className={`flex gap-4 xl:w-[440px] font-satoshi font-semibold ${''}`}>
          <p className="text-lg text-bash">{item.instructionNo}</p>
          <div className="flex flex-col gap-2">
            <p
              className={`text-xl ${
                item.isCancelled ? 'text-bash line-through' : 'text-bblack-1 '
              } truncate`}>
              {item.title}
            </p>
            <div className="flex row items-center gap-1 font-medium text-sm">
              <span className=" text-bblack-1">
                {item.isCancelled ? 'Cancelled by: ' : 'Issued by: '}
                <span className="text-bash">
                  {item.isCancelled
                    ? `${team?.data[item.cancelledBy || '']?.name ?? ""} on ${toMonthDayCommaYear(
                        item.cancelledOn
                      )}`
                    : item.issuedBy}
                </span>
              </span>
            </div>
          </div>
        </div>
        {
          <div onClick={(e) => e.stopPropagation()} className="xl:hidden">
            <ActionIconWithModal
              ismobile={'this is showing'}
              item={item}
              isModalOpen={isActionModalOpen}
              setIsModalOpen={(value: boolean) => setIsActionModalOpen(value)}
            />
          </div>
        }
      </div>
      <div className={`hidden xl:block ${item.isCancelled ? ' ' : ''} `}>
        <RecipientHoverCard recipients={item.recipients ?? []} isItemCancelled={item.isCancelled} />
      </div>
      <p
        className={`hidden xl:block text-sm text-bash font-satoshi font-medium ${
          item.isCancelled ? 'line-through ' : ''
        }`}>
        {toMonthDayCommaYear(item.issuedDate)}
      </p>
      <div
        className={`flex justify-between items-center pl-6 xl:hidden ${
          item.isCancelled ? ' ' : ''
        }`}>
        <RecipientHoverCard recipients={item.recipients ?? []} isItemCancelled={item.isCancelled} />
        <p
          className={`text-sm text-bash font-satoshi font-medium ${
            item.isCancelled ? 'line-through ' : ''
          }`}>
          {toMonthDayCommaYear(item.issuedDate)}
        </p>
      </div>
      <div
        className={`hidden xl:flex items-center gap-1 ${hoverFade} `}
        onClick={() => !item.isCancelled && onOpenDetailModal(item, index)}>
        <p className="text-sm text-bash font-satoshi font-medium">Instruction Details</p>
        <FaAngleRight color="#9099a8" />
      </div>
      {
        <div className="hidden xl:block">
          <ActionIconWithModal
            item={item}
            isModalOpen={isActionModalOpen}
            setIsModalOpen={(value: boolean) => setIsActionModalOpen(value)}
          />
        </div>
      }
    </section>
  );
};
