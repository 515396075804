import React, { useEffect, useMemo } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import BudgetHistoryTable from './budgetTable';
import ValuationItem from './ValuationItem';
import { orderValuations } from './constants';

const history = [
  {
    amount: 4000,
    notes: 'very good valuation from bnkle valuers',
    date: new Date().toISOString()
  }
];

const ValuationHistory = () => {
  let navigate = useNavigate();
  let finance = useAppSelector((m) => m.finance);
  let { pathname } = useLocation();
  const valuationWithOrder = useMemo(() => {
    return orderValuations(finance.data.valuation);
  }, [finance.data]);
  useEffect(() => {
    if (!finance?.data?._id) {
      let link = pathname.split('/');
      navigate(link.slice(0, link.length - 2).join('/'));
    }
  }, []);
  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="flex sticky bg-projectBg top-0 z-20 items-center pb-2  text-sm cursor-pointer">
        <span className="text-borange mr-2 flex  items-center">
          <BsChevronLeft size={16} className="text-borange" />
        </span>
        <span className="text-bash ">{`${finance.data.bidName} Finance`}</span>
      </div>
      <p className=" font-semibold mt-10">Valuation History</p>

      <div className=" bg-white flex-col flex gap-y-4  w-full p-8 mt-4 rounded-md flex-1 overflow-y-auto scrollbar-thin ">
        {valuationWithOrder?.map((m) => <ValuationItem key={m._id} {...m} />)}
      </div>
    </div>
  );
};

export default ValuationHistory;
