import React, { FC, useCallback, useState } from 'react';
import Button from 'components/shared/Button';
import SuperModal from 'components/shared/SuperModal';
import { CgClose } from 'react-icons/cg';
import { PiWarningCircleFill } from 'react-icons/pi';
import { ProcurementType } from '../layout/types';
import { useAppDispatch } from 'store/hooks';
import { postForm } from 'apis/postForm';
import { displayError, displaySuccess } from 'Utils';
import { removeProcurement } from 'store/slices/procurementSlice';
import { useParams } from 'react-router-dom';

interface Props {
  closer: () => void;
  value?: ProcurementType;
}

export const DeleteProcurementModal: FC<Props> = ({ closer }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  let { procurementId } = useParams();

  const archiveProcurement = useCallback(async () => {
    setLoading(true);

    const payload = {
      dashboardId: procurementId
    };

    const { e, response } = await postForm(
      'patch',
      `procurements/archive?dashboardId=${procurementId}`,
      payload
    );

    if (response) {
      displaySuccess('Procurement deleted successfully');
      dispatch(removeProcurement(procurementId));
      closer();
    } else {
      displayError(e?.message || '');
    }

    setLoading(false);
  }, [procurementId, closer]);

  return (
    <SuperModal
      classes=" bg-black bg-opacity-60 flex flex-col items-center overflow-y-auto"
      closer={closer}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white rounded-md py-6 mt-20 mb-10 w-1/2 max-w-[500px] flex flex-col gap-4 ">
        <div className="flex items-center justify-between px-6">
          <div className="flex items-center gap-2">
            <span>
              <PiWarningCircleFill className="text-redShade-0" fontSize={20} />
            </span>
            <p className=" text-xl font-Medium text-bblack-1">Delete Procurement</p>
          </div>

          <span className=" cursor-pointer text-sm text-bash" onClick={closer}>
            <CgClose fontSize={24} color="#9099A8" />
          </span>
        </div>

        <div className="border border-pbg  text-bash text-lg font-normal px-6 py-6">
          <p>Are you sure you want to delete this procurement</p>
          <p>You cannot undo this.</p>
        </div>

        <div className=" flex justify-end gap-x-4 px-6">
          <Button onClick={closer} type="secondary" text="Cancel" />
          <Button
            isLoading={loading}
            onClick={archiveProcurement}
            type="danger"
            text="Delete Procurement"
          />
        </div>
      </div>
    </SuperModal>
  );
};
