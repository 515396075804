import { FC, useMemo, useState } from 'react';
import { RecipientHoverModal } from './RecipientHoverModal';
import { Recipient } from './types';
import { isArrayNullOrEmpty } from 'Utils';

interface Props {
  recipients: Recipient[];
  color?: string;
  backgroundColor?: string;
  isItemCancelled?: boolean;
}

export const RecipientHoverCard: FC<Props> = ({
  recipients,
  color = 'text-bgreen-4',
  backgroundColor = 'bg-bgreen-5',
  isItemCancelled = false
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const recipientMessage = useMemo(() => {
    return recipients.length > 0 ? recipients.length : 'No recipients';
  }, [recipients.length]);

  const recipientText = useMemo(() => {
    if (isArrayNullOrEmpty(recipients)) {
      return 'No Recipient';
    }
    return `${recipients.length} Recipient${recipients?.length > 1 ? 's' : ''}`;
  }, [recipients]);

  return (
    <div
      className={`relative cursor-pointer`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <p
        className={`text-sm ${
          !isItemCancelled ? color + ' ' + backgroundColor : 'text-bash  bg-ashShade-0 '
        } font-satoshi whitespace-nowrap font-medium  py-1 px-2 rounded-[20px]`}>
        {recipientText}
      </p>
      {isHovered && !isItemCancelled && <RecipientHoverModal recipients={recipients} />}
    </div>
  );
};
