import React, { useEffect, useRef, useState } from 'react';
import SuperModal from 'components/shared/SuperModal';
import { RecentPaymentsModalProps } from './types';
import { GrAttachment } from 'react-icons/gr';
import { postForm } from 'apis/postForm';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { closeModal, updateField } from 'store/slices/financeSlice';
import Loader, { LoaderX } from 'components/shared/Loader';
import { displayError, displaySuccess, displayWarning } from 'Utils';
import NumberInput, { NumberInputWithLabel } from 'components/shared/NumberInput';
import InputField from 'components/shared/InputField';
import ProjectMoneyInputField from 'components/shared/ProjectMoneyInputField';
import SelectDate from 'components/shared/SelectDate';
import SelectMultiple from 'components/shared/SelectMultipleField/SelectMultiple';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from 'components/shared/Button';
import SelectField from 'components/shared/SelectField';
import { convertToProper } from 'components/shared/utils';

const tags = ['variation', 'none', 'fluctuation'].map((m) => ({
  label: convertToProper(m),
  value: m
}));

let initialValue = {
  paymentDate: new Date(),
  contractorBudget: 0,
  materialBudget: 0,
  adminFees: 0,
  otherFees:0,
  tag: 'none'
};

export let numberSchema = yup.number().required().not(['0'], 'Field cannot be zero');
const RecentPaymentsModal = ({ setter }: RecentPaymentsModalProps) => {
  let fileRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  let { data: finance } = useAppSelector((m) => m.finance);

  let dispatch = useAppDispatch();

  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm, isValid } =
    useFormik({
      initialValues: initialValue,
      onSubmit: (data) => {
        updateBudget(data);
      },
      validationSchema: yup.object({
        paymentDate: yup.string().required(),
        contractorBudget: numberSchema,
        materialBudget: numberSchema,
        adminFees: numberSchema,
        tag: yup.string(),
        otherFees: numberSchema
      }),
      validateOnBlur: true
    });

  const updateBudget = async (data: Record<string, any>) => {
    setLoading(true);
    let { e, response } = await postForm('patch', 'financials/budget/add', {
      ...data,
      financialsId: finance._id,
    });
    if (response) {
      dispatch(updateField({ data: response.data.data, field: 'projectBudget' }));
      displaySuccess('Update recorded');
      setter(false);
    } else {
      displayError(e?.message || 'Could not update budget');
    }

    setLoading(false);
  };


  return (
    <SuperModal
      closer={() => setter(false)}
      classes="bg-black bg-opacity-80 flex justify-center overflow-y-auto">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white h-max mt-[10%] rounded-lg w-full max-w-[484px] p-4">
        <div className="flex mb-6 hover:cursor-pointer items-center justify-between">
          <span className="font-semibold">Project Cost Estimate</span>
          <span onClick={() => setter(false)} className="hover:underline text-bash text-sm">
            Close
          </span>
        </div>
        <NumberInputWithLabel
          label="Contractor Budget"
          labelClassName=" text-sm "
          value={values.contractorBudget}
          setter={(n: number) => setFieldValue('contractorBudget', n)}
          className="w-full font-Medium text-bblack-0 mb-6 outline-bblue border mt-2 border-bblue rounded-md px-4 py-2"
        />

        <NumberInputWithLabel
          label="Material Budget"
          labelClassName=" text-sm "
          value={values.materialBudget}
          setter={(n: number) => setFieldValue('materialBudget', n)}
          className="w-full font-Medium text-bblack-0 mb-6 outline-bblue border mt-2 border-bblue rounded-md px-4 py-2"
        />
        <NumberInputWithLabel
          label="Admin Fee"
          labelClassName=" text-sm "
          value={values.adminFees}
          setter={(n: number) => setFieldValue('adminFees', n)}
          className="w-full font-Medium text-bblack-0 mb-6 outline-bblue border mt-2 border-bblue rounded-md px-4 py-2"
        />

        <NumberInputWithLabel
          label="Other Fees"
          labelClassName=" text-sm "
          value={values.otherFees}
          setter={(n: number) => setFieldValue('otherFees', n)}
          className="w-full font-Medium text-bblack-0 mb-6 outline-bblue border mt-2 border-bblue rounded-md px-4 py-2"
        />

        <SelectDate
          className=" mt-8"
          placeholder="dd/mm/yy"
          wrapperClassName=" !border-ashShade-4 "
          minDate={new Date(0)}
          maxDate={new Date()}
          initialValue={values?.paymentDate ? new Date(values.paymentDate) : new Date()}
          value={values.paymentDate}
          label="Payment Date"
          onChange={(e) => {
            console.log(e);
            if (e) {
              setFieldValue('paymentDate', e.toISOString());
            }
          }}
        />

        <SelectField
          value={convertToProper(values.tag)}
          data={tags}
          className=" mt-6"
          label="Tag"
          onChange={(e) => {
            setFieldValue('tag', e);
          }}
        />

        <div className="flex font-satoshi mt-4 items-center justify-end">
          <Button
            text="Cancel"
            type="secondary"
            onClick={() => {
              setter(false);
            }}
          />

          <Button
            onClick={() => handleSubmit()}
            className=" ml-4"
            isLoading={loading}
            type="primary"
            disabled={!isValid}
            text={'Set Budget'}
          />
        </div>
      </div>
    </SuperModal>
  );
};

export default RecentPaymentsModal;
