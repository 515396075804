import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa6';
import { hoverFade } from 'constants/globalStyles';
import { SiteInstruction, SiteInstructionChange } from './types';
import { isArrayNullOrEmpty, toMonthDayCommaYear } from 'Utils';
import parse from 'html-react-parser';
import '../../../../shared/CustomEditor/style.css';
import { GetDownloadSignedUrls } from 'apis/AwsFiles';
import { RecipientHoverCard } from './RecipientHoverCard';
import { ActionIconWithModal } from './ActionIconWithModal';
import { IDocument } from '@cyntler/react-doc-viewer';

import { FilePreviewModal } from './FilePreviewModal';
import { postForm } from 'apis/postForm';
import { useAppSelector } from 'store/hooks';
import { convertToProper } from 'components/shared/utils';

interface Props {
  item: SiteInstruction;
  change: SiteInstructionChange;

  //   onClose: () => void;
}

export const formatDateToMonthDateYearTime = (date: string = new Date().toISOString()) => {
  let left = toMonthDayCommaYear(date);
  let month = new Date(date).toLocaleString('default', { month: 'long' });
  let _localtime = new Date(date).toLocaleString();
  let localtime = _localtime.slice(_localtime.length - 10);
  let time = localtime.slice(0, 5);
  return month + left.slice(3) + ` ${time}` + localtime.slice(localtime.length - 2).toLowerCase();
};

export const ActivityCard: FC<Props> = ({ item, change }) => {
  const [imageUrls, setImageUrls] = useState<{ uri: string; fileType: string }[]>([]);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  let { data } = useAppSelector((m) => m.team);

  const [config, setConfig] = useState<IDocument[]>([]);
  const [fetching, setFetching] = useState(false);

  const recipientLabel = useMemo(() => {
    if (item.recipients && isArrayNullOrEmpty(item.recipients)) {
      return 'No Recipient';
    }
    return item.recipients!.length === 1 ? `Recipient` : 'Recipients';
  }, [item]);

  const getImgUrl = useCallback(async (path: string) => {
    const res = await GetDownloadSignedUrls(path, 'bnkle-professional-docs');
    return res.data.url;
  }, []);

  const fetchImageUrls = useCallback(async () => {
    if (!item.media) {
      return;
    }

    setFetching(true);
    let _urls = await Promise.all(
      item.media.map((m) =>
        postForm('post', 'files/download', {
          Bucket: 'bnkle-professional-docs',
          S3Key: m.S3Key
        })
      )
    );
    let viewerConfig: IDocument[] = [];

    let urls = _urls.map((m, i) => {
      if (m.response) {
        viewerConfig.push({
          uri: m.response.data.data.url,
          fileType: item.media ? item?.media[i]?.ContentType : '',
          fileName: item.media ? item?.media[i]?.S3Key : ''
        });
      }
    });

    setImageUrls(viewerConfig.map((m) => ({ uri: m.uri, fileType: m.fileType || '' })));
    setConfig(viewerConfig);
    setFetching(false);
  }, [item, getImgUrl]);

  useEffect(() => {
    fetchImageUrls();
  }, [fetchImageUrls]);
  let { image, name, initials } = useMemo(() => {
    return data[change.updatedBy as string];
  }, [data]);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className=" flex  w-full gap-x-3">
      {image ? (
        <img className="w-8 h-8 rounded-full " src={image} alt="" />
      ) : (
        <span className=" bg-ashShade-3 text-bash w-8 h-8 rounded-full flex items-center justify-center">
          {initials}
        </span>
      )}
      <div className=" flex-1 ">
        <div className="xl:flex items-center  justify-between mb-3 ">
          <p className=" text-sm mt-1.5  ">
            <span>{`${convertToProper(name)} `}</span>
            <span className=" text-bash ml-2">{' modified the site instruction content.'}</span>
            <span
              onClick={() => {
                setIsViewing(!isViewing);
              }}
              className=" text-black ml-2 cursor-pointer font-semibold underline">
              {isViewing ? 'Hide' : 'View'}
            </span>
          </p>
          <span className=" text-xs mt-1.5 xl:mt-0 text-bash">
            {formatDateToMonthDateYearTime(change.timestamp)}
          </span>
        </div>

        {isViewing && (
          <section
            className={`flex flex-col gap-4 p-4 w-full rounded-md bg-ashShade-0 font-satoshi`}>
            <div className="flex flex-col gap-6 lg:flex-row justify-between">
              <div className="flex  items-center justify-between">
                <div className="flex items-center gap-3">
                  <div>
                    <p className="text-sm text-bblack-1 font-satoshi font-semibold">
                      {item.instructionNo}. {item.title}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-between gap-8 text-xs font-satoshi font-medium">
                <div>
                  <p className="text-bblack-1"> Issued by</p>
                  <p className="text-bash">{change.issuedBy}</p>
                </div>
                <div>
                  <p className="text-bblack-1">{recipientLabel}</p>
                  <RecipientHoverCard
                    backgroundColor=" bg-white "
                    color="  text-bash text-xs "
                    recipients={item.recipients ?? []}
                  />
                </div>
                <div>
                  <p className="text-bblack-1">Date issued</p>
                  <p className="text-bash">{new Date(change.issuedDate).toDateString().slice(4)}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 text-xs font-satoshi font-medium ">
              {/* <p className="text-bblack-1">Instructions</p> */}
              <div className="rsw-ce ul rsw-ce ol">
                <p className="text-bash">{parse(change.instruction)}</p>
              </div>
            </div>
            {/* {!isArrayNullOrEmpty(imageUrls) && (
              <div className="flex flex-col gap-2 text-xs font-satoshi font-medium">
                <p className="text-bblack-1">Attached files</p>
                <div className="grid grid-cols-3 lg:grid-cols-4 gap-4 w-[356px] lg:w-[526px]">
                  {imageUrls.map((url, index) => (
                    <div
                      key={index}
                      className=" w-[107.39px] h-[72px] lg:w-[120px] lg:h-[80px] overflow-hidden rounded-md"
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveIndex(index);
                        setIsModalOpen(true);
                      }}>
                      <img
                        src={url.uri}
                        alt={`Attached file ${index + 1}`}
                        className="w-full h-full object-cover rounded-md"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )} */}
            {isModalOpen && (
              <FilePreviewModal
                activeIndex={activeIndex}
                onClose={() => setIsModalOpen(false)}
                fetching={fetching}
                config={
                  item.media?.map((m) => ({
                    S3Key: m.S3Key,
                    Bucket: 'bnkle-professional-docs',
                    ContentType: m.ContentType,
                    alias: m.S3Key,
                    project: 'pp',
                    folder: 'f',
                    timestamp: ''
                  })) || []
                }
              />
            )}
          </section>
        )}
      </div>
    </div>
  );
};
