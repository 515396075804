import { convertToProper, toDecimalPlaceWithCommas } from 'components/shared/utils';
import React, { useContext, useMemo, useRef } from 'react';
import { IoClose, IoEllipsisVertical } from 'react-icons/io5';
import { BudgetProps, TranchProps } from '../types';
import { useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayError, displaySuccess, displayWarning, formatWithComma } from 'Utils';
import { updateField, openModal } from 'store/slices/financeSlice';
import Loader, { LoaderX } from 'components/shared/Loader';
import { StoreContext } from 'context';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { FaEllipsisVertical } from 'react-icons/fa6';
// import DetailModal from './book-keeping/DetailModal';
import { useClickOutSideComponent } from '../../Team/Views/Components/OnScreen';
import SuperModal from 'components/shared/SuperModal';
import { MdOutlineClose } from 'react-icons/md';

const Budget = (m: BudgetProps) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const dispatch = useAppDispatch();
  const user = useAppSelector((m) => m.user);
  const { data } = useAppSelector((m) => m.finance);
  const { selectedProject, activeProject } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(false);
  const optionsRef = useRef(null);
  const [viewing, setViewing] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);

    let { e, response } = await postForm('delete', `financials/${data._id}/tranch/${m._id}`);
    if (e) {
      displayWarning('Could not delete payment');
    } else {
      let newList =
        (data.disbursements && data.disbursements.filter((j: any) => j._id !== m._id)) || [];
      dispatch(updateField({ field: 'disbursements', data: newList }));
    }

    setDeleting(false);
  };

  let code = useMemo(() => {
    return activeProject?.currency?.code;
  }, []);

  useClickOutSideComponent(optionsRef, () => {
    setOptions(false);
  });

  const tagClass = useMemo(() => {
    let className = ' py-1 px-2 text-xs font-semibold rounded-full   ';
    let options = {
      variation: '  text-bblue bg-lightblue',
      fluctuation: ' text-byellow-1 bg-byellow-0 ',
      none: ' bg-ashShade-0'
    };

    className += options[m.tag as keyof typeof options];

    return className;
  }, [m.tag]);
  let generalClass = 'px-2 whitespace-nowrap py-5 ';
  return (
    <>
      <tr className=" text-bash text-sm w-full border-b-bash ">
        <td className=" pl-4 px-2">{m.s_n}</td>
        <td className={generalClass}>
          {new Date(m?.paymentDate || new Date()).toDateString().slice(4)}
        </td>
        <td className={generalClass+" text-black font-semibold "}>
          {code}{' '}
          {formatWithComma(
            m.adminFees + m.contractorBudget + m.materialBudget + (m?.otherFees || 0)
          )}
        </td>
        <td className={generalClass}>
          {code} {formatWithComma(m.contractorBudget || 0)}
        </td>
        <td className={generalClass}>
          {code} {formatWithComma(m?.materialBudget || 0)}
        </td>
        <td className={generalClass}>
          {code} {formatWithComma(m?.adminFees || 0)}
        </td>
        <td className={generalClass}>
          {code} {formatWithComma(m?.otherFees || 0)}
        </td>
        {/* <td className={generalClass}>
          {code} {formatWithComma(m?.others || 0)}
        </td> */}
        <td className="py-2 relative px-2 ">
          <span className={tagClass}>{convertToProper(m.tag)}</span>
        </td>
      </tr>
    </>
  );
};

export const Item = ({
  label,
  value,
  underline,
  valueClass
}: {
  label?: string;
  value?: string;
  underline?: boolean;
  valueClass?: string;
}) => {
  return (
    <div
      className={` py-4  flex items-center justify-between ${
        underline ? ' border-b-2 border-ashShade-3  ' : ''
      } `}>
      <span className=" text-black text-sm font-semibold ">{label}</span>
      <span className={`text-bash ${valueClass}`}>{value}</span>
    </div>
  );
};

export default Budget;
