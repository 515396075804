import React, { useEffect, useMemo, useState } from 'react';
import { VscChecklist } from 'react-icons/vsc';
import Budget from './Budget';
import { BudgetProps, BudgetsProps } from '../types';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { openModal } from 'store/slices/financeSlice';
import useRole from 'Hooks/useRole';
import PaginationComponent from '../book-keeping/PaginationComponent';
import { LuChevronsUpDown } from 'react-icons/lu';
import SuperModal from 'components/shared/SuperModal';

const numberperPage = 10;
const BudgetHistoryTable = ({ modalToggler, budgets }: BudgetsProps) => {
  let user = useAppSelector((m) => m.user);
  let { data, modal } = useAppSelector((m) => m.finance);
  let dispatch = useAppDispatch();
  let { canSeeSnapshot, canAddTranch } = useRole();
  const [isAscending, setisAscending] = useState(false);
  const [viewing, setViewing] = useState(false);
  let numberofPages = Math.ceil(budgets.length / numberperPage);

  const [current, setCurrent] = useState(0);
  useEffect(() => {
    if (budgets.length === 0 || !budgets) {
      dispatch(openModal({ name: 'tranch', isEditing: false }));
    }
  }, []);

  const orderedList = useMemo(() => {
    return [...budgets].sort((a, b) => {
      if (!a?.paymentDate || !b?.paymentDate) return 0;

      let timeValueA = new Date(a.paymentDate || new Date()).getTime();
      let timeValueB = new Date(b.paymentDate || new Date()).getTime();

      return isAscending ? timeValueA - timeValueB : timeValueB - timeValueA;
    });
  }, [budgets, isAscending]);

  const _data = useMemo(() => {
    return orderedList.slice(current * numberperPage, (current + 1) * numberperPage);
  }, [current, orderedList]);

  useEffect(() => {
    setCurrent(0);
  }, [budgets]);
  let generalClass = 'py-6 whitespace-nowrap px-2';
  return (
    <>
      <div className="w-full h-full pb-10 mt-4  overflow-y-auto">
        <div className=" bg-white max-h-[100%] overflow-y-auto pb-6  h-full  rounded-md ">
          <div className="  overflow-y-auto flex flex-col h-full  px-6 scrollbar w-full">
            {budgets.length > 0 ? (
              <>
                <table className="  bg-white w-full ">
                  <thead>
                    <tr className="  border-b  font-semibold">
                      <td className=" py-6 pl-4 whitespace-nowrap">S/N</td>
                      <td className="py-6 px-2 flex  items-center whitespace-nowrap">
                        <span>Date</span>
                        <span
                          onClick={() => {
                            setisAscending(!isAscending);
                          }}
                          className=" p-1 ml-2 cursor-pointer rounded-full hover:bg-ashShade-0 ">
                          <LuChevronsUpDown />
                        </span>
                      </td>
                      <td className={generalClass + ' pl-2 '}>Estimated Total budget</td>
                      <td className={generalClass}>Contractor budget</td>
                      <td className={generalClass}>Material budget</td>
                      <td className={generalClass}>Admin Fee</td>
                      <td className={generalClass}>Other Fees</td>
                      {/* <td className={generalClass}>Other budget</td> */}
                      <td className={generalClass}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {_data.map((m, i) => (
                      <Budget
                        key={m._id}
                        s_n={current * numberperPage + i + 1}
                        {...m}
                        // setEditing={setEditing}
                      />
                    ))}
                  </tbody>
                </table>
                <PaginationComponent
                  pageIndex={current}
                  pageCount={numberofPages}
                  setPageIndex={setCurrent}
                />
              </>
            ) : (
              <div className="flex-col items-center flex my-12">
                <VscChecklist size={32} color="#77828D" />
                <p className="text-bash mt-2">No budgets created yet</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetHistoryTable;
