import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';

const useMembers = () => {
  let team = useAppSelector((m) => m.team);

  const memberList = useMemo(() => {
    const allMembers = Object.values(team.data);
    return (
      allMembers?.map((member) => ({
        name: member?.name,
        id: member?._id,
        userType: member?.type
      })) || []
    );
  }, [team]);

  return { memberList };
};

export default useMembers;
