import { useMemo, useState } from 'react';
import { Material } from './types';

import NoContent from 'components/projects/photos/NoContent';
import { LoaderX } from 'components/shared/Loader';
import PaginationComponent from './components/PaginationComponent';
import ArrowIcons from './components/ArrowIcons';
import SupplyTableItem from './components/SupplyTableItem';

interface Props {
  data: Material[];
  loading?: boolean;
  onEdit: (supply: Material) => void;
}
const numberperPage = 20;
const SupplyTable = ({ data, loading, onEdit }: Props) => {
  let numberofPages = Math.ceil(data?.length / numberperPage);
  const [current, setCurrent] = useState(0);

 const _data = useMemo(() => {
   const sortedData = [...data].reverse(); 
   return sortedData.length
     ? sortedData.slice(current * numberperPage, (current + 1) * numberperPage)
     : [];
 }, [current, data]);



  return (
    <div className="rounded-md">
      {loading ? (
        <div className=" w-full flex rounded-md bg-white  items-center justify-center p-10">
          <LoaderX color="blue" />
        </div>
      ) : data?.length > 0 ? (
        <>
          <table className="  bg-white w-full ">
            <thead>
              <tr className="  border-b  font-semibold">
                <td className=" py-4 pl-4">S/N</td>
                <td className="py-4">Order Number</td>
                <td className="py-4">Material</td>
                <td className="py-4">Quantity</td>
                <td className="py-4">Vendor</td>
                <td className="py-4">Recieved By</td>
                <td className="py-4">Supplied Date</td>
                <td className="py-4">Notes</td>
              
                <td className="py-4"></td>
              </tr>
            </thead>
            <tbody>
              {_data?.map((m, i) => {
                const { ...rest } = m;
                return (
                  <SupplyTableItem
                    key={i}
                    s_n={current * numberperPage + i + 1}
                    {...rest}
                    onEdit={onEdit}
                  />
                );
              })}
            </tbody>
          </table>
          <PaginationComponent
            pageIndex={current}
            pageCount={numberofPages}
            setPageIndex={setCurrent}
          />
        </>
      ) : (
        <NoContent
          subtitle="Keep a record of supplies needed to complete the project"
          title="No Supply recorded Yet"
        />
      )}
    </div>
  );
};

export default SupplyTable;
