import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SuperModal from 'components/shared/SuperModal';
import { postForm } from 'apis/postForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayError, displaySuccess, displayWarning } from 'Utils';
import NumberInput, { NumberInputWithLabel } from 'components/shared/NumberInput';
import { closeModal, populate, updateField } from 'store/slices/financeSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { numberSchema } from './BudgetModal';
import SelectDate from 'components/shared/SelectDate';
import Button from 'components/shared/Button';
import { TbPlus, TbTrash } from 'react-icons/tb';
import InputField from 'components/shared/ProjectMoneyInputField/InputField';
import { ValuationModalProps } from './types';
import TextArea from 'components/shared/TextArea';

let initialValue: {
  dateOfValuation: Date;
  amount: number;
  notes?: string;
} = {
  dateOfValuation: new Date(),
  amount: 0,
  notes: ''
};

const ValuationModal = ({ closer, value }: ValuationModalProps) => {
  const [fetching, setFetching] = useState(false);
  let { data, modal } = useAppSelector((m) => m.finance);
  let dispatch = useAppDispatch();

  let { errors, values, setFieldValue, handleChange, handleSubmit, touched, resetForm, isValid } =
    useFormik({
      initialValues: value
        ? { ...value, dateOfValuation: new Date(value?.dateOfValuation || new Date()) }
        : initialValue,
      onSubmit: (data) => {
        handleSubmission(data);
      },
      validationSchema: yup.object({
        dateOfValuation: yup.string().required(),
        amount: numberSchema,
        notes: yup.string().notRequired()
      }),
      validateOnBlur: true
    });


  // useEffect(() => {
  //   if (modal.isEditing) {
  //     let initialValue =
  //       (data.disbursements && data.disbursements.filter((m) => m._id === modal._id)) || [];
  //     setValues((prev: any) => {
  //       return { ...initialValue[0] };
  //     });
  //   }
  // }, []);
  const handleSubmission = async (_data: Record<string, any>) => {
    setFetching(true);
    let body = {
      ..._data,
      financialsId: data._id
    } as Record<string, any>;
    if (body?._id) {
      body.valuationId = body._id;
      delete body?._id;
    }

    let { response, e } = await postForm(
      'patch',
      `financials/valuation/${value ? 'update' : 'add'}`,
      body
    );
    if (response) {
      dispatch(populate(response?.data?.data));
      displaySuccess(`Valuation ${value ? 'added' : 'updated'} Successfully`);
      closer();
    } else {
      displayWarning(`Could not ${value ? 'add' : 'update'} valuation`);
    }

    setFetching(false);
  };

  return (
    <SuperModal
      closer={closer}
      classes="bg-black bg-opacity-80 flex justify-center overflow-y-auto pb-3 ">
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=" bg-white h-fit mt-[10%] rounded-lg w-full max-w-[484px] p-4">
        <div className="flex mb-6 hover:cursor-pointer items-center justify-between">
          <span className="font-Medium text-xl">Record Valuation</span>
          <span onClick={closer} className="hover:underline text-bash text-sm">
            Close
          </span>
        </div>
        <NumberInputWithLabel
          placeholder={`eg. 25,000`}
          label="Amount"
          labelClassName=" text-sm "
          value={values?.amount || 0}
          setter={(n: number) => setFieldValue('amount', n)}
          className="w-full font-Medium text-bblack-0 mb-6 outline-bblue border mt-2  rounded-md px-4 py-2"
        />

        <SelectDate
          className=" mb-6"
          // error={((touched.date && errors?.date) || '') as string}
          placeholder="Select date"
          wrapperClassName=" !border-ashShade-4 "
          minDate={new Date(0)}
          maxDate={new Date()}
          initialValue={value ? new Date(value?.dateOfValuation || new Date()) : new Date()}
          value={values.dateOfValuation}
          label="Date of Valuation"
          onChange={(e) => {
            console.log(e);
            if (e) {
              setFieldValue('dateOfValuation', e.toISOString());
            }
          }}
        />

        <TextArea
          value={values.notes}
          onChange={handleChange}
          name="notes"
          className=" !mt-1 !mx-2"
          label="Notes (Optional)"
          placeholder="Enter a description"
        />

        <div className="flex mt-6 items-center justify-end">
          <Button text="Cancel" type="secondary" onClick={closer} />
          <Button
            isLoading={fetching}
            onClick={() => handleSubmit()}
            className=" ml-4"
            text={`${value ? 'Update' : 'Record'} Valuation`}
          />
        </div>
      </span>
    </SuperModal>
  );
};

export default ValuationModal;
