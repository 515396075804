import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  TbAt,
  TbBold,
  TbItalic,
  TbList,
  TbListNumbers,
  TbUnderline,
  TbUpload,
  TbX
} from 'react-icons/tb';
import {
  Editor,
  EditorProvider,
  Toolbar,
  Separator,
  createButton,
  ContentEditableEvent
} from 'react-simple-wysiwyg';
import './style.css';
import { errorStyle } from 'constants/globalStyles';
import { isArrayNullOrEmpty } from 'Utils';
import { DocIcon, PdfIcon, TxtIcon, VideoIcon } from 'assets/icons';
import { useClickOutSideComponent } from 'components/projects/Team/Views/Components/OnScreen';

interface Props {
  label?: string;
  labelClassName?: string;
  textValueOnChange: (value: string) => void;
  fileValueOnChange?: (files: File[]) => void;
  textValue: string;
  fileValue?: File[];
  error?: string;
  placeholder?: string;
  memberData: { id: string; name: string }[];
  height?: string;
  isEditing?: boolean;
  setRemovedFiles?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CustomEditor: FC<Props> = ({
  label,
  labelClassName,
  textValueOnChange,
  textValue,
  fileValue,
  fileValueOnChange,
  error,
  placeholder,
  memberData,
  height,
  isEditing,
  setRemovedFiles
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const editableRef = useRef<HTMLDivElement>(null);
  const mentionDropdownRef = useRef<HTMLDivElement>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [showMentionDropdown, setShowMentionDropdown] = useState(false);
  const [mentionPosition, setMentionPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0
  });

  const editorButtonData = useMemo(
    () => [
      { label: 'Italic', icon: <TbItalic />, command: 'italic' },
      { label: 'Bold', icon: <TbBold />, command: 'bold' },
      { label: 'Underline', icon: <TbUnderline />, command: 'underline' },
      { label: 'Numbered list', icon: <TbListNumbers />, command: 'insertOrderedList' },
      { label: 'Bullet list', icon: <TbList />, command: 'insertUnorderedList' }
    ],
    []
  );

  const renderEditorButtons = () =>
    editorButtonData.map(({ label, icon, command }) =>
      createButton(label, <div className="ml-2 text-gray-500">{icon}</div>, command)
    );

  useEffect(() => {
    if (fileValue) {
      const newUrls = fileValue.map((file) => {
        if (file.type.startsWith('image/')) return URL.createObjectURL(file);
        if (file.type === 'application/pdf') return PdfIcon;
        return DocIcon;
      });
      setImageUrls(newUrls);
    }
  }, [fileValue]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files) {
      const fileArray = Array.from(files);
      const newThumbnailUrls = fileArray.map((file) => {
        if (file.type.startsWith('image/')) return URL.createObjectURL(file);
        if (file.type === 'application/pdf') return PdfIcon;
        return DocIcon;
      });

      setImageUrls((prev) => [...prev, ...newThumbnailUrls]);

      if (fileValueOnChange) {
        fileValueOnChange([...(fileValue || []), ...fileArray]);
      }
    }
  };

  const handleRemoveFile = useCallback(
    (index: number) => {
      if (imageUrls[index]) {
        URL.revokeObjectURL(imageUrls[index]);
      }
      setImageUrls((prevFiles) => prevFiles.filter((_, i) => i !== index));
      if (fileValueOnChange) {
        const updatedFileValue = fileValue?.filter((_, i) => i !== index);
        fileValueOnChange(updatedFileValue || []);
      }
      if (isEditing && setRemovedFiles && fileValue) {
        const removedFile = fileValue[index].name;
        setRemovedFiles((prevRemovedFiles: string[]) => [...prevRemovedFiles, removedFile]);
      }
    },
    [imageUrls]
  );

  const handleEditorChange = (event: ContentEditableEvent) => {
    textValueOnChange(event.target.value);
  };

  const handleMentionClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const editor = editorRef.current;
    const mention = mentionDropdownRef.current;

    if (editor) {
      const rect = editor.getBoundingClientRect();

      let dropDownDimensions = mention?.getBoundingClientRect();
      let dropDownWidth = dropDownDimensions?.width ?? 0;
      let dropDownHeight = dropDownDimensions?.width ?? 0;
      let left = e.clientX - rect.left;
      let top = e.clientY - rect.top;
      if (left + dropDownWidth > rect.width) {
        left = rect.width - dropDownWidth;
      }
      if (top + dropDownHeight > rect.height) {
        top = rect.height - dropDownHeight;
      }

      setMentionPosition({
        top,
        left
      });

      setShowMentionDropdown(true);
    }
  };
  let currentId = useRef<string | null>(null);
  const insertMention = (name: string) => {
    const mentionText = ` @${name} `;
    const id = Date.now();
    const styledMentionText = `&nbsp;<span id="${id.toString()}" style="background-color:#add2ee; color: #1264a3; padding:1px;">${mentionText}</span>&nbsp;`;
    currentId.current = id.toString();
    let element = document.getElementsByClassName('rsw-ce')[0];
    let res = handleChange(styledMentionText);
    let possibles = ['', '<br>'];
    textValueOnChange(!possibles.includes(res) ? res : styledMentionText);
    setShowMentionDropdown(false);
    element = document.getElementsByClassName('rsw-ce')[0];
    let selection = document.getSelection();
    let currentNode = document.getElementById(id.toString());
    selection?.setPosition(currentNode);
  };

  useEffect(() => {
    return () => {
      imageUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [imageUrls]);

  const editorStyles = {
    height: height ?? '260px',
    backgroundColor: 'white',
    borderColor: '#e4e6ea',
    border: '1px solid #e4e6ea',
    scrollbarWidth: 'thin' as 'thin',
    overflowY: 'scroll' as 'scroll',

    ...(!isArrayNullOrEmpty(imageUrls) && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: 0
    })
  };

  useClickOutSideComponent(mentionDropdownRef, () => setShowMentionDropdown(false));

  const handleChange = (styleText: string = '') => {
    let editable = document.getElementsByClassName('rsw-ce')[0];
    let range = editable.childNodes;
    let selection = document.getSelection();

    if (selection) {
      let newStart = selection?.getRangeAt(0);
      const findAndReplaceNode = (node: ChildNode): ChildNode => {
        if (node.isSameNode(selection?.focusNode as Node)) {
          let left = node.textContent?.slice(0, newStart?.startOffset) || '';
          let right = node.textContent?.slice(newStart?.startOffset) || '';
          let newtext = left + styleText + right;
          let newNode = document.createElement('span');
          newNode.innerHTML = newtext;
          return newNode;
        }

        if (node.hasChildNodes() && node.contains(selection?.focusNode as ChildNode)) {
          let newNode = document.createElement('span');
          node.childNodes.forEach((element) => {
            newNode.appendChild(findAndReplaceNode(element));
          });
          return newNode;
        }
        let newNode = document.createElement('span');
        let newnode = node.cloneNode(true);
        newNode.appendChild(newnode);
        return newNode.childNodes[0];
      };
      let fragment = document.createElement('span');
      range.forEach((m) => {
        let resp = findAndReplaceNode(m);
        fragment.appendChild(resp);
      });
      return fragment.innerHTML;
    }
    return '';
  };

  useEffect(() => {
    if (currentId?.current) {
      let target = document.getElementById(currentId.current);
      let selection = document.getSelection();
      selection?.setPosition(target?.nextSibling || target, 1);
      currentId.current = null;
    }
  }, [textValue]);

  return (
    <EditorProvider>
      {label && (
        <p className={`text-sm text-bash font-semibold mt-3 mb-1 ${labelClassName}`}>{label}</p>
      )}
      <div className="relative">
        <div ref={editorRef}>
          <Editor
            value={textValue}
            onChange={handleEditorChange}
            containerProps={{ style: editorStyles, ref: editableRef }}
            placeholder={placeholder}>
            <div className="sticky top-0">
              <Toolbar>
                {renderEditorButtons().map((Btn, idx) => (
                  <Btn key={idx} />
                ))}
                <Separator />

                <button
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                  className="ml-2 text-gray-500"
                  onClick={handleMentionClick}>
                  <TbAt />
                </button>
                <label htmlFor="file-upload" className="cursor-pointer">
                  <TbUpload color="#9099a8" size={16} className="ml-2" />
                  <input
                    id="file-upload"
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png,.gif,.webp,.pdf"
                    className=" hidden "
                  />
                </label>
              </Toolbar>
            </div>
          </Editor>
        </div>
        {error && <p className={errorStyle}>{error}</p>}

        {!isArrayNullOrEmpty(imageUrls) && (
          <div className="flex flex-wrap gap-4 rounded-b px-1  border-t-0 border h-[54px] overflow-y-scroll scrollbar-thin mb-6">
            {imageUrls.map((src, index) => (
              <div
                key={index}
                className="relative w-[56px] h-[48px] bg-ashShade-0 rounded-[4px] flex items-center justify-center gap-2">
                <img
                  src={src}
                  alt={`Attached file ${index + 1}`}
                  className="w-full h-full object-cover rounded-[4px]"
                />
                <button
                  className="absolute -top-1 -right-2 flex items-center justify-center p-1 w-[16px] h-[16px] bg-ashShade-0 text-white rounded-full"
                  onClick={() => handleRemoveFile(index)}>
                  <TbX color="#5E6777" size={12} />
                </button>
              </div>
            ))}
          </div>
        )}

        {
          <div
            className={` max-h-44 overflow-y-auto scrollbar-thin ${
              showMentionDropdown ? '' : ' invisible '
            }`}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            style={{
              position: 'absolute',
              top: mentionPosition.top,
              left: mentionPosition.left,
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              zIndex: 1000,
              padding: '10px',
              borderRadius: '4px'
            }}
            ref={mentionDropdownRef}>
            {memberData.map((member) => (
              <div
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                key={member.id}
                className="cursor-pointer hover:bg-gray-200 p-1"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  insertMention(member.name);
                }}>
                {member.name}
              </div>
            ))}
          </div>
        }
      </div>
    </EditorProvider>
  );
};
