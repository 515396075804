import React, { useRef, useEffect, useMemo, createElement, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { MessageEvent, MessageImage, update } from 'store/slices/chatsSlice';
import moment from 'moment';
import useOnScreen from './OnScreen';
import { handleStatusUpdate } from 'pages/projects/Project/OneProject';
import { BiCheckDouble, BiCheck } from 'react-icons/bi';
import UsePending from './UsePending';
import parse from 'html-react-parser';
import { FilePreviewModal } from '../siteInstructions/FilePreviewModal';
import { Doc } from 'components/projects/documents/drawings/DocModal';
import { getFileUrl } from 'components/shared/utils';
import { LatestDoc } from 'components/projects/documents/document-repo/SingleDocModal';
import { PdfIcon } from 'assets/icons';

const randomColor = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};

let color = randomColor();

const Message = ({
  message,
  origin,
  _id,
  isFirstPending,
  destination,
  reads
}: MessageEvent & { isFirstPending: boolean }) => {
  let user = useAppSelector((m) => m.user);
  let chat = useAppSelector((m) => m.chats);
  let team = useAppSelector((m) => m.team);
  let dispatch = useAppDispatch();
  let isMine = useMemo(() => user._id === origin, []);
  let myGroup = useMemo(() => (isMine ? destination : origin), []);
  let messageRef = useRef<HTMLDivElement>(null);
  let markerRef = useRef<HTMLDivElement>(null);
  let onscreen = useOnScreen(messageRef);
  let unreads = UsePending(chat.selection?.id || '', chat?.selection?.type as unknown as any);

  const [showUnread, setshowUnread] = useState(false);

  let status = useMemo(() => {
    if (destination === message.projectId || message?.groupId) {
      let myreads = reads?.filter((m) => m.userId !== user._id);
      if (!myreads || myreads.length < 1) return 'pending';
      let acc: { [key: string]: number } = {};
      myreads?.forEach((m) => {
        if (acc[m.status]) {
          acc[m.status]++;
        } else {
          acc[m.status] = 1;
        }
      });
      if (acc['pending']) return 'pending';
      if (acc['delivered']) return 'delivered';
      if (acc['read']) return 'read';
    } else {
      return message.status;
    }
  }, [chat]);

  useEffect(() => {
    const messageHandler = () => {
      if (destination === message.projectId && onscreen) {
        let read: false;
        let myRead = reads?.find((m) => m.userId === user._id && m.status !== 'read' && !isMine);
        if (myRead) {
          handleStatusUpdate(_id || '', 'read', (message) => {
            if (message) {
              dispatch(update(message));
            }
          });
        }
      } else {
        if (onscreen && message.status !== 'read' && !isMine) {
          handleStatusUpdate(_id || '', 'read', (message) => {
            if (message) {
              dispatch(update(message));
            }
          });
        }
      }
    };

    messageHandler();

    return () => {};
  }, [onscreen]);

  useEffect(() => {
    if (!onscreen && isFirstPending) {
      messageRef.current?.scrollIntoView({ behavior: 'auto', block: 'center' });
    }
  }, []);

  return (
    <>
      {isFirstPending && unreads > 0 && <UnReadMarker number={unreads} />}
      <div
        ref={messageRef}
        className={`max-w-[70%] min-w-[20%] px-3 py-1 my-2 rounded-xl  ${
          isMine
            ? '  self-end rounded-tr-none bg-bblue text-white '
            : ' rounded-tl-none self-start bg-ashShade-0 text-bblack-1 '
        } p-4 relative `}>
        <p
          className=" text-sm md:text-base font-semibold   "
          style={{ color: !isMine ? color : '' }}>
          {isMine ? 'You' : team.data[origin]?.name || 'Admin'}
        </p>
        {message.images && message.images.length > 0 && <MessageImages images={message.images} />}
        <div className="max-w-full text-xs xl:text-sm message ">
          {parse(`${message?.body ?? ''}`)}
        </div>

        <div className="text-right mt-1 leading-3 text-xxs w-full flex justify-end">
          {message.createdAt ? moment(new Date(message.createdAt)).format('h:mm a') : ''}
          {isMine && (
            <span className="ml-2">
              {createElement(status === 'pending' ? BiCheck : BiCheckDouble, {
                size: 14,
                color: status === 'read' ? '#A8D461' : 'white'
              })}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

interface Props {
  number: number;
  // ref: any;
}
export const UnReadMarker = ({ number }: Props) => {
  return (
    <div className="py-0.5 px-2 my-4 self-center bg-ashShade-0 text-bblack  rounded-full mt-2">{`${number} Unread Message${
      number > 1 ? 's' : ''
    }`}</div>
  );
};

interface Images {
  images: MessageImage[];
}

export const MessageImages = ({ images }: Images) => {
  const [showPreview, setShowPreview] = useState(false);
  const [active, setActive] = useState(0);

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  useEffect(() => {
    let getImages = async () => {
      let responses = await Promise.all(
        images
          .slice(0, 4)
          .map((m) =>
            m.ContentType.endsWith('pdf') ? PdfIcon : getFileUrl(m as unknown as LatestDoc)
          )
      );

      setImageUrls(responses as string[]);
    };

    getImages();
  }, []);
  const firstFour = useMemo(() => {
    return images.slice(0, 4);
  }, []);

  const config = useMemo(() => {
    return images.map((m) => ({ ...m, alias: '', folder: '', project: '' }));
  }, []);

  let imageContainerClass = useMemo(() => {
    let className = ' grid ';
    if (images.length >= 4) {
      className += ' grid-cols-2 ';
    }

    return className;
  }, []);

  const togglePreview =
    (i = 0) =>
    () => {
      setShowPreview(!showPreview);
      setActive(i);
    };

  return (
    <>
      {showPreview && (
        <FilePreviewModal activeIndex={active} onClose={togglePreview()} config={config} />
      )}
      <div className={`${imageContainerClass} gap-2`}>
        {imageUrls.map((m, i) => (
          <div className="relative " onClick={togglePreview(i)}>
            {i === 3 && images.length > 4 && (
              <div className="absolute cursor-pointer top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-60 text-white text-5xl bg-black">
                +{images.length - 4}
              </div>
            )}
            <img className=" w-full rounded-md cursor-pointer  " src={m} />
            {m === PdfIcon && <div className=" mt-2">{images[i].S3Key}</div>}
          </div>
        ))}
      </div>
    </>
  );
};

export default Message;
