import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SiteInstruction } from './types';
import { ActionModal } from './ActionModal';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { useClickOutSideComponent } from '../Components/OnScreen';
import useRole from 'Hooks/useRole';
import { useAppDispatch } from 'store/hooks';
import { postForm } from 'apis/postForm';
import { removeSiteInstruction, updateSiteInstruction } from 'store/slices/siteInstructionSlice';
import { displayError, displaySuccess } from 'Utils';
import { SiteInstructionFormModal } from './SiteInstructionFormModal';
import { CancelModal } from './CancelModal';
import { TheOption } from 'pages/projects/Home/Components/ProjectCard';
import { TbEdit, TbMessages, TbTrash } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { setSelection } from 'store/slices/chatsSlice';
import CommentModal from './CommentModal';

interface Prop {
  item: SiteInstruction;
  isModalOpen?: boolean;
  setIsModalOpen?: (value: boolean) => void;
  ismobile?: any;
}

export const ActionIconWithModal: FC<Prop> = ({ item, isModalOpen, setIsModalOpen, ismobile }) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSiteInstructionFormModal, setShowSiteInstructionFormModal] = useState(false);
  const [options, setOptions] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  let navigate = useNavigate();

  const { canCreateBrief: canAccessSiteInstruction } = useRole();

  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);

  useClickOutSideComponent(modalRef, () => {
    let newVal = !options;
    setOptions(newVal);
  });

  const handleEdit = () => {
    setShowSiteInstructionFormModal(true);
    setTimeout(() => {
      setOptions(false);
    }, 1000);
  };

  const handleCancel = useCallback(async () => {
    setLoading(true);

    const { e, response } = await postForm(
      'patch',
      `site-instruction/cancel?instructionId=${item._id}`
    );

    if (response) {
      displaySuccess('Site instruction canceled successfully');
      dispatch(updateSiteInstruction(response?.data?.data));
    } else {
      displayError(e?.message || 'Could not cancel site instruction');
    }
    setLoading(false);
    setShowDeleteModal(false);
  }, [dispatch, item]);
  const handleClose = () => {
    setOptions(!options);
  };

  useClickOutSideComponent(modalRef, () => {
    setOptions(false);
  });

  const commentOnSiteInstruction = () => {
    setCommentModal(true);
    // navigate('../chats');
    // dispatch(
    //   setSelection({
    //     id: 'params.userId',
    //     type: 'Site Instructions'
    //   })
    // );
  };

  return (
    <>
      {showSiteInstructionFormModal && (
        <SiteInstructionFormModal
          closer={() => {
            setShowSiteInstructionFormModal(false);
          }}
          isEditing={Boolean(item)}
          value={item}
        />
      )}

      {showDeleteModal && (
        <CancelModal
          title="Cancel Site Instruction"
          cancelWhat="site instruction"
          buttonText="Cancel Site Instruction"
          closer={() => {
            setShowDeleteModal(false);
          }}
          handleCancelSubmit={handleCancel}
          isLoading={loading}
        />
      )}

      {commentModal && <CommentModal closer={() => setCommentModal(false)} siteInstruction={item} />}

      <div className="relative cursor-pointer">
        <span
          className="p-2 flex items-center justify-center rounded-full hover:bg-ashShade-0"
          onClick={handleClose}>
          <FaEllipsisVertical color="#24282e" />
        </span>
        <span className="">
          {options && (
            <div ref={modalRef}>
              <div className="absolute top-full right-0 mt-1 w-[180px] bg-white rounded-md shadow-bnkle border border-ashShade-0 z-10">
                {canAccessSiteInstruction && !item.isCancelled && (
                  <TheOption
                    loading={loading}
                    icon={TbEdit}
                    text="Edit"
                    className=" px-4"
                    onClick={handleEdit}
                  />
                )}
                <TheOption
                  loading={loading}
                  icon={TbMessages}
                  text="Comment"
                  className=" px-4"
                  onClick={commentOnSiteInstruction}
                />
                {canAccessSiteInstruction && !item.isCancelled && (
                  <TheOption
                    loading={loading}
                    className=" hover:text-redShades-2 text-redShades-2 hover:bg-redShades-1 px-4"
                    iconColor="#B63434"
                    iconHoveredColor="#B63434"
                    icon={TbTrash}
                    text="Cancel"
                    onClick={() => setShowDeleteModal(true)}
                  />
                )}
              </div>
            </div>
          )}
        </span>
      </div>
    </>
  );
};
